// const tableUpdateObj = {
//   addColumns: [],
//   updateColumns: [],
//   deleteColumns: [],
//   renameColumns: {},
//   dropTable: false,
//   createTable: true,

import { relationTypesObject } from 'lib/erp-data-table/components/data/erpData';
import { getIDColumnName } from 'lib/erp-data-table/components/util/utilFunctions';

// }
export const updateRelatedTableSchema = ({
  allDataTableData,
  appID,
  mainTableName,
  tableUpdates,
  setTableUpdates,
  setRelatedTableList,
  relationData,
  action,
}) => {
  // Get the target table name
  const targetTableName = relationData.target;
  let newColumnSchema;
  let junctionTable;
  // Determine the inverse relation type
  let inverseType;
  // Determine the foreign key, columnSchema and junction-table for the inverse relation
  let inverseForeignKey;

  const relationName = relationData?.relationName;

  if (relationData.type === 'hasMany' || relationData.type === 'hasOne') {
    inverseType = 'belongsTo';
    // inverseForeignKey = `__d3id__${mainTableName}Id`;
    inverseForeignKey = getIDColumnName(mainTableName, relationName);
    newColumnSchema = [
      {
        name: `${inverseForeignKey}`,
        columnName: inverseForeignKey,
        dataType: 'belongsTo',
        relationId: relationData.as,
      },
      // {
      //   name: relationData.foreignKey,
      //   columnName: relationData.foreignKey,
      //   dataType: 'belongsTo',
      //   relationId: relationData.as,
      // },
    ];
  } else if (relationData.type === relationTypesObject.belongsTo.value) {
    inverseType = 'hasMany';
    inverseForeignKey = `id`;
    // inverseForeignKey = `__d3id__${targetTableName}Id`;
  } else if (relationData.type === 'belongsToMany') {
    inverseType = 'belongsToMany';
    inverseForeignKey = `__d3id__${targetTableName}Id`;
    // inverseForeignKey = getIDColumnName(targetTableName, relationName);
    // const tableName = `${mainTableName}-${relationData.target}-${relationData?.relationName}-throughTable`;
    const tableName = relationData.through;

    junctionTable = {
      tableID: relationData.through,
      appID,
      tableType: 'junction',
      columnSchema: [
        {
          name: `${relationData.foreignKey} ID`,
          columnName: relationData.foreignKey,
          dataType: 'number',
        },
        {
          name: `${relationData.otherKey} ID`,
          columnName: relationData.otherKey,
          dataType: 'number',
        },
      ],
      // name: relationData.through,
      name: tableName,
      updates: {
        renameColumns: {},
        createTable: true,
        addColumns: [],
        updateColumns: [],
        deleteColumns: [],
        dropTable: false,
      },
    };
  }

  // Determine the inverse relation key and data
  const inverseRelationKey = relationData.as;
  const inverseRelationData = {
    as: inverseRelationKey,
    type: inverseType,
    foreignKey: inverseForeignKey,
    relationName: relationData.relationName,
    target: mainTableName,
  };

  // If the relation type is belongsToMany, add extra properties
  if (relationData.type === relationTypesObject.belongsToMany.value) {
    inverseRelationData.through = relationData.through;
    // inverseRelationData.otherKey = `__d3id__${mainTableName}Id`;
    inverseRelationData.otherKey = getIDColumnName(mainTableName);
    inverseForeignKey = getIDColumnName(mainTableName, relationName);
  }
  let skipRelation = false;
  // Update the target table schema
  setRelatedTableList((prevSchema) => {
    let targetTableSchema = {
      ...(prevSchema[targetTableName] || allDataTableData[targetTableName]),
    };

    const relations = { ...targetTableSchema.relations };

    if (action === 'add') {
      relations[inverseRelationKey] = inverseRelationData;

      // if (relationData.type === 'hasMany' || relationData.type === 'hasOne') {
      //   setRelatedTableUpdates((prevData) => {
      //     const newUpdates = { ...( prevData[targetTableName] || tableUpdateObj) }
      //     newUpdates['addColumns'] = [...(newUpdates.addColumns || []), newColumnSchema];
      //     return {
      //       ...prevData,
      //       [targetTableName]: newUpdates,
      //     };
      //   });
      // }

      if (
        relationData?.type === relationTypesObject.hasMany.value ||
        relationData?.type === relationTypesObject.hasOne.value
      ) {
        if (
          !(targetTableSchema?.columnSchema || []).some(
            (obj) => obj.columnName === newColumnSchema[0].columnName
          )
        ) {
          const updatedColumnSchema = [
            ...(targetTableSchema?.columnSchema ?? {}),
            ...newColumnSchema,
          ];

          const updatedAddColumns = [
            ...(targetTableSchema?.updates?.addColumns ?? []),
            ...newColumnSchema,
          ];

          targetTableSchema = {
            ...targetTableSchema,
            columnSchema: updatedColumnSchema,
            updates: {
              ...(targetTableSchema?.updates ?? {}),
              createTable: false,
              addColumns: updatedAddColumns,
            },
          };
        } else {
          skipRelation = true;
          return prevSchema;
        }
      }
      if (
        relationData?.type === relationTypesObject.belongsTo.value ||
        relationData?.type === relationTypesObject.belongsToMany.value
      ) {
        targetTableSchema = {
          ...targetTableSchema,
          updates: {
            ...(targetTableSchema?.updates ?? {}),
            createTable: false,
          },
        };
      }
    } else if (action === 'update') {
      relations[inverseRelationKey] = inverseRelationData;
      // if (relationData.type === 'hasMany' || relationData.type === 'hasOne') {
      //   setRelatedTableUpdates((prevData) => {
      //     const newUpdates = { ...( prevData[targetTableName] || tableUpdateObj) }
      //     newUpdates['updateColumns'] = [...(newUpdates.updateColumns || []), newColumnSchema];
      //     return {
      //       ...prevData,
      //       [targetTableName]: newUpdates,
      //     };
      //   });

      // }
    } else if (action === 'remove') {
      delete relations[inverseRelationKey];
      //   if (relationData.type === 'hasMany' || relationData.type === 'hasOne') {

      //   setRelatedTableUpdates((prevData) => {
      //     const newUpdates = { ...( prevData[targetTableName] || tableUpdateObj) }
      //     newUpdates['deleteColumns'] = [...(newUpdates.deleteColumns || []), newColumnSchema];
      //     return {
      //       ...prevData,
      //       [targetTableName]: newUpdates,
      //     };
      //   });
      // }
      let isAddColumnDelete = false;
      let isNormalColumnDelete = false;
      if (
        relationData?.type === relationTypesObject.hasMany.value ||
        relationData?.type === relationTypesObject.hasOne.value
      ) {
        const updatedColumnSchema = (targetTableSchema?.columnSchema || []).filter((obj) => {
          const isNotFound = obj.columnName !== newColumnSchema[0].columnName;
          if (!isNotFound) {
            isNormalColumnDelete = true;
          }
          return isNotFound;
        });
        const updatedAddColumns = (targetTableSchema?.updates?.addColumns || []).filter((obj) => {
          const isNotFound = obj.columnName !== newColumnSchema[0].columnName;
          if (!isNotFound) {
            isAddColumnDelete = true;
          }
          return isNotFound;
        });
        let updatedDeleteColumns = targetTableSchema?.updates?.deleteColumns ?? [];

        if (!isAddColumnDelete && isNormalColumnDelete) {
          updatedDeleteColumns = [...updatedDeleteColumns, ...newColumnSchema];
        }
        targetTableSchema = {
          ...targetTableSchema,
          columnSchema: updatedColumnSchema,
          updates: {
            ...(targetTableSchema?.updates ?? {}),
            createTable: false,
            addColumns: updatedAddColumns,
            deleteColumns: updatedDeleteColumns,
          },
        };
      }
    }
    targetTableSchema = {
      ...targetTableSchema,
      relations: relations,
    };

    return {
      ...prevSchema,
      [targetTableName]: targetTableSchema,
    };
  });

  if (relationData.type === relationTypesObject.belongsToMany.value) {
    if (!Object.keys(allDataTableData).includes(junctionTable.name)) {
      setRelatedTableList((prevSchema) => ({
        ...prevSchema,
        [junctionTable.tableID]: junctionTable,
      }));
    }
  }
  return { skipRelation };
};
