import React from 'react';
import { mls } from 'lib/multilanguagesupport';
import PopUpDivChild from 'lib/reusable-components/PopUpDivChild/PopUpDivChild';
import { highlight, languages } from 'prismjs/components/prism-core';
import { MdContentCopy } from 'react-icons/md';
// import Editor from 'react-simple-code-editor';
import Editor from '@monaco-editor/react';

import { toast } from 'react-toastify';
import './queryGenerator.scss';
interface AIToQueryProps {
  aiQuaryData: any;
  isAIQuaryPopup: boolean;
  setIsAIQuaryPopup: Function;
  handleCodeChange: Function;
  code: string;
  defaultLanguage?: string;
}
const AIToQuery = ({
  isAIQuaryPopup,
  setIsAIQuaryPopup,
  aiQuaryData,
  handleCodeChange,
  code,
  defaultLanguage = 'javascript',
}: AIToQueryProps) => {
  const copyQueryToClipBoard = () => {
    const finalText = `${aiQuaryData}`;
    // Copy the text inside the text field
    navigator.clipboard.writeText(finalText);
    // Alert the copied text
    toast.success(mls('Copied the Query '));
  };
  const closeSqlAIQueryPopup = () => {
    setIsAIQuaryPopup(false);
  };
  const handleUseButton = () => {
    handleCodeChange(`${code}\n${aiQuaryData}`);
    setIsAIQuaryPopup(false);
  };
  return (
    <PopUpDivChild
      initialValue={isAIQuaryPopup}
      setPopUp={closeSqlAIQueryPopup}
      className={'AIQueryArea'}
      popupTitle={mls('Query History')}
      // popupTitle={mls('Result From your Query')}
    >
      <div className='AIQueryAreaContainer'>
        <div className='editorArea'>
          <div className='editorTitle' onClick={() => copyQueryToClipBoard()}>
            {mls('Commands')}
            <span className='copyQueryToClipBoardIcon'>
              <MdContentCopy />
            </span>
          </div>
          <div className='mainEditorArea'>
            <Editor
              value={aiQuaryData}
              height='100%'
              defaultLanguage={defaultLanguage}
              onChange={() => {}}
              theme='vs-dark'
              options={{
                // automaticLayout: true,
                // formatOnPaste: true,
                // formatOnType: true,
                readOnly: true,
              }}
            />
          </div>
        </div>
        <div className='useButtonDiv' style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <button
            className='btn btn-sm btn-primary'
            type='button'
            style={{ marginLeft: 'auto' }}
            onClick={() => handleUseButton()}
          >
            {mls('Use')}
          </button>
        </div>
      </div>
    </PopUpDivChild>
  );
};

export default AIToQuery;
