import React, { useRef, useState, useEffect, useMemo } from 'react';
import clsx from 'clsx';
import { mls } from 'lib/multilanguagesupport';
import styles from '../columnSettings.module.scss';
import { erpAllDataType, erpValidationTypes } from '../../../data/erpData';
import AddValidation from './AddValidation';
import ValidationFields from './ValidationFields';
import { getValidationDataByType, updatedValidaitonOption } from './validationUtils';

const ValidationSection = ({
  columnSchema,
  handleUpdateColumnData,
  handleTableUpdateColumnName,
}: {
  columnSchema: { [key: string]: any };
  handleUpdateColumnData: Function;
  handleTableUpdateColumnName: Function;
}) => {
  const sectionRef = useRef(null);
  const [localValidationData, setLocalValidationData] = useState<any>(
    columnSchema?.validation ?? []
  );

  const [validationError, setValidationError] = useState('');
  const handleChangeValidation = (e: any, valIndex: number, field: any) => {
    e.preventDefault();
    const value = e.target.value;
    setLocalValidationData((prevLocalValidationData: any) => {
      const updatedValidation: any[] = Array.from(prevLocalValidationData ?? []);
      if (!updatedValidation[valIndex]) {
        updatedValidation[valIndex] = {};
      }

      updatedValidation[valIndex] = {
        ...updatedValidation[valIndex],
        [field]: value,
      };

      if (field === 'type') {
        if (value === erpValidationTypes.isLength.key) {
          updatedValidation[valIndex] = {
            ...updatedValidation[valIndex],
            minValue: 0,
            maxValue: 1,
          };
        } else {
          let copyValidationElem = { ...(updatedValidation[valIndex] ?? {}) };
          delete copyValidationElem.minValue;
          delete copyValidationElem.maxValue;
          updatedValidation[valIndex] = copyValidationElem;
        }
        const validationDefaultData = getValidationDataByType({
          validationType: updatedValidation[valIndex].type,
        });
        updatedValidation[valIndex] = {
          ...updatedValidation[valIndex],
          message: validationDefaultData?.message,
        };
      }

      // Check for errors
      const currentValidation = updatedValidation[valIndex];
      let error = '';

      if (field === 'type' && !value) {
        error = 'Validation type is required';
      } else if (field === 'message' && !value.trim()) {
        error = 'Error message is required';
      }

      if (error) {
        currentValidation.error = error;
      } else {
        delete currentValidation.error;
      }
      return updatedValidation;
    });
  };
  const deleteValidation = (valIndex: number) => {
    setValidationError('');
    setLocalValidationData((prevLocalValidationData: any) => {
      const updatedValidation = (prevLocalValidationData ?? []).filter(
        (_: any, index: number) => index !== valIndex
      );
      return updatedValidation;
    });
  };

  const handleSectionBlur = () => {
    const { hasErrors, errorMessage } = checkForErrors(localValidationData);
    if (hasErrors) {
      setValidationError(errorMessage);
    } else {
      setValidationError('');
      handleTableUpdateColumnName({
        columnData: { ...columnSchema, validation: localValidationData },
        columnName: columnSchema?.columnName,
      });
      handleUpdateColumnData({ ...columnSchema, validation: localValidationData });
    }
  };

  const checkForErrors = (schema: any) => {
    const validations = schema || [];
    let hasErrors = false;
    let errorMessage = '';

    validations.forEach((validation: any) => {
      if (validation.type === 'isLength') {
        if (!validation.minValue || !validation.maxValue) {
          hasErrors = true;
          errorMessage = 'Length range must be specified for isLength validation';
        } else if (parseInt(validation.minValue) > parseInt(validation.maxValue)) {
          hasErrors = true;
          errorMessage = 'Min length cannot be greater than max length';
        }
      }

      if (validation.type === 'isRequired' && !validation.message.trim()) {
        hasErrors = true;
        errorMessage = 'Required fields must have an error message';
      }
    });

    return { hasErrors, errorMessage };
  };

  useEffect(() => {
    setLocalValidationData(columnSchema?.validation ?? []);
  }, [columnSchema]);

  const newValidationOption = useMemo(() => {
    const validationOptions = erpAllDataType[columnSchema?.dataType as keyof typeof erpAllDataType]
      ?.validation ?? { isRequired: erpValidationTypes.isRequired };
    return updatedValidaitonOption({
      validationOptions: validationOptions,
      localValidationData: localValidationData,
    });
  }, [columnSchema?.dataType, localValidationData]);
  return (
    <div
      className={styles.userOuterContainer}
      ref={sectionRef}
      onBlur={handleSectionBlur}
      key={columnSchema?.type ?? ''}
    >
      <p className='mb-5 fs-6 fw-bolder text-dark'>{mls('Validation')}</p>
      <div style={{ maxHeight: 300, overflowY: 'auto', overflowX: 'hidden' }}>
        {!localValidationData?.length && (
          <p className='text-muted px-4'>{mls('No validations added.')} </p>
        )}
        {localValidationData?.length !== 0 && (
          <div className='row'>
            <div className='col-6 '>
              <label className='form-label mx-9 fs-6 fw-bolder text-dark'>{mls('Type')}</label>
            </div>
            <label className='col-6 form-label fs-6 fw-bolder text-dark'>
              {mls('Error Message')}
            </label>
          </div>
        )}
        {localValidationData?.map((val: any, key: number) => {
          const handleDelete = () => {
            deleteValidation(key);
          };
          const handleValidationUpdate = (e: any, type: any) => {
            handleChangeValidation(e, key, type);
          };
          const selectedValidationType =
            erpValidationTypes?.[val?.type as keyof typeof erpValidationTypes];
          let validationOptions = newValidationOption;
          if (selectedValidationType) {
            validationOptions = {
              [val?.type]: selectedValidationType,
              ...validationOptions,
            };
          }

          return (
            <ValidationFields
              validationData={val}
              validationOptions={validationOptions}
              handleChangeValidation={handleValidationUpdate}
              handleDelete={handleDelete}
              isDeleteOption
              key={val?.type}
            />
          );
        })}
      </div>
      <AddValidation
        validationOptions={newValidationOption}
        localValidationData={localValidationData}
        setLocalValidationData={setLocalValidationData}
      />
      {validationError && <div className='text-danger mt-2'>{validationError}</div>}
    </div>
  );
};

export default ValidationSection;
