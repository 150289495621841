import React, { useEffect, useState } from 'react';
import arrowDropDownIcon from './assets/arrowDropDownIcon.svg';
import SlideSwitch from './features/SlideSwitch/SlideSwitch';
import { BiCubeAlt } from 'react-icons/bi';
import { defaultDataTypeAllData } from './data/defaultFilterSchema';

interface AllTablesProps {
  selectedColumns: any;
  handleColumnSelection: any;
  handleRemoveColumnSelection: any;
  newAllTableData: any;
  tableFilter: any;
  isDashboard: boolean;
}
export const AllTables = (props: AllTablesProps) => {
  const {
    selectedColumns,
    handleColumnSelection,
    handleRemoveColumnSelection,
    tableFilter,
    newAllTableData,
    isDashboard,
  } = props;
  if (isDashboard) {
    return (
      <>
        {React.Children.toArray(
          newAllTableData.map((dashBoardElem: any, sheetIx: number) => {
            return (
              <>
                <div className='dashboardFilterColumnContainer'>
                  <div className='dashboardName'>
                    <BiCubeAlt fontSize={'1.2rem'} /> {dashBoardElem?.dashboardName}
                  </div>
                  {React.Children.toArray(
                    dashBoardElem.items.map((elem: any, i: number) => {
                      const isOpen = sheetIx === 0 && i === 0;
                      return (
                        <>
                          <FilterSelection
                            isDashboard={isDashboard}
                            elem={elem}
                            i={i}
                            tableFilter={tableFilter}
                            selectedColumns={selectedColumns}
                            handleColumnSelection={handleColumnSelection}
                            handleRemoveColumnSelection={handleRemoveColumnSelection}
                            isOpen={isOpen}
                          />
                        </>
                      );
                    })
                  )}
                </div>
              </>
            );
          })
        )}
      </>
    );
  }
  return (
    <>
      {React.Children.toArray(
        newAllTableData.map((elem: any, i: number) => {
          return (
            <>
              <FilterSelection
                isDashboard={isDashboard}
                elem={elem}
                i={i}
                tableFilter={tableFilter}
                selectedColumns={selectedColumns}
                handleColumnSelection={handleColumnSelection}
                handleRemoveColumnSelection={handleRemoveColumnSelection}
              />
            </>
          );
        })
      )}
    </>
  );
};
interface mainProps {
  selectedColumns: any;
  handleColumnSelection: any;
  handleRemoveColumnSelection: any;
  elem: any;
  i: number;
  tableFilter: any;
  isOpen?: boolean;
  isDashboard: boolean;
}
const FilterSelection = (props: mainProps) => {
  const {
    elem,
    selectedColumns,
    handleColumnSelection,
    handleRemoveColumnSelection,
    tableFilter,
    isDashboard,
    isOpen = true,
  } = props;
  const [isDivOpen, setisDivOpen] = useState(isOpen);

  return (
    <div className={isDivOpen ? 'tablesItems open' : 'tablesItems'}>
      <div className='tablesItemTitle' onClick={() => setisDivOpen(!isDivOpen)}>
        {elem.tableName}
        <img src={arrowDropDownIcon} alt='' />
      </div>
      <div className='tablesItemList'>
        {React.Children.toArray(
          elem?.items?.map((elem: any) => {
            return (
              <FilterTableColumn
                target={elem}
                isDashboard={isDashboard}
                selectedColumns={selectedColumns}
                handleColumnSelection={handleColumnSelection}
                handleRemoveColumnSelection={handleRemoveColumnSelection}
                tableFilter={tableFilter}
              />
            );
          })
        )}
      </div>
    </div>
  );
};

export default FilterSelection;

interface FilterTableColumnProps {
  target: any;
  selectedColumns: any;
  handleColumnSelection: any;
  handleRemoveColumnSelection: any;
  tableFilter: any;
  isDashboard: boolean;
}
const FilterTableColumn = ({
  target,
  handleColumnSelection,
  selectedColumns,
  handleRemoveColumnSelection,
  tableFilter,
  isDashboard,
}: FilterTableColumnProps) => {
  const [isChecked, setisChecked] = useState(false);
  const handleChecked = () => {
    setisChecked((currentState) => {
      if (!currentState) {
        handleColumnSelection(target);
      } else {
        handleRemoveColumnSelection(target);
      }
      return !currentState;
    });
  };

  const addFilter = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();

    setisChecked((currentState) => {
      if (!currentState) {
        handleColumnSelection(target);
        return !currentState;
      }
      return currentState;
    });
  };
  const stopPropagation = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
  };

  useEffect(() => {
    if (isDashboard) {
      const isTrue = selectedColumns.some(
        (e: any) => e.uniqueDashboardID === target.uniqueDashboardID
      );
      setisChecked(isTrue);
      return;
    }
    const column = `${target.tableName}.${target.columnName}`;
    const isTrue = selectedColumns.some((e: any) => `${e.tableName}.${e.columnName}` === column);
    setisChecked(isTrue);
  }, [selectedColumns, tableFilter]);
  const dataTypeElem = defaultDataTypeAllData?.[target?.dataType];
  const Icon = dataTypeElem?.icon ?? null;
  const isFunction = typeof Icon === 'function';

  return (
    <>
      <div className='filterTable' title={target?.name} onClick={(e) => addFilter(e)}>
        <div className='titleSection'>
          <div
            className='titleSectionIcon'
            style={{ fontSize: dataTypeElem?.fontSize, color: dataTypeElem?.color }}
          >
            {isFunction ? <Icon /> : dataTypeElem?.icon}
          </div>
          <div className='titleSectionText'>{target?.name}</div>
        </div>
        <div className='toggleSection' onClick={(e) => stopPropagation(e)}>
          <SlideSwitch setIsChecked={handleChecked} isChecked={isChecked} />
        </div>
      </div>
    </>
  );
};
