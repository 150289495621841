import React, { useState } from 'react';
import styles from '../../ComponentSettingWrapper.module.scss';
import SlideSwitch from 'lib/reusable-components/Components/SlideSwitch/SlideSwitch';
import { mls } from 'lib/multilanguagesupport';
import PopUpDivChild from 'lib/reusable-components/PopUpDivChild/PopUpDivChild';
import { relationTypeObjects, specialDataTypeName } from '../../data/appComponentData';
import {
  getConnectedTablesNew,
  getFinalTableID,
  getIDColumnName,
  tableDataToRelatedTableData,
} from '../../utils/componentSettingFunction';
import useStoreSchema from 'lib/reusable-components/reusableUtils/commons/useStoreSchema';
import reduxConstants from 'lib/reusable-components/reusableUtils/redux/reduxConstants';

interface mainConnectedTableSelectionProps {
  connectedTablesData: { [key: string]: any };
  componentSchema: any;
  setRelatedTableList: Function;
  relatedTableList: { [key: string]: any }[];
}

const MainConnectedTableSelection = ({
  // connectedTablesData,
  componentSchema,
  relatedTableList,
  setRelatedTableList,
}: mainConnectedTableSelectionProps) => {
  const [isSelectedTablePopup, setIsSelectedTablePopup] = useState(false);
  const handleIsSelectedTablePopup = () => {
    setIsSelectedTablePopup(!isSelectedTablePopup);
  };
  const appSchema = useStoreSchema(reduxConstants.STORE_NAME, reduxConstants.config.APP_SCHEMA);
  const appDatatable = appSchema?.appDatatable || {};
  const connectedTablesData = getConnectedTablesNew({
    tableId: componentSchema.table,
    appDatatable,
  });

  const handleInnerSelections = ({
    innerBelongsToKey,
    innerBelongsToObj,
    selectedTableIndex,
    innerBelongsToIndex,
  }: any) => {
    if (componentSchema.table === innerBelongsToKey) return;
    const updatedData = [...relatedTableList];
    if (!updatedData[selectedTableIndex].tableList) {
      updatedData[selectedTableIndex] = { ...updatedData[selectedTableIndex], tableList: [] };
      updatedData[selectedTableIndex].tableList.push(
        tableDataToRelatedTableData({ tableData: innerBelongsToObj })
      );
    } else {
      if (innerBelongsToIndex > -1) {
        const dataAfterRemoval = [...updatedData[selectedTableIndex].tableList];
        dataAfterRemoval.splice(innerBelongsToIndex, 1);
        updatedData[selectedTableIndex].tableList = dataAfterRemoval;
      } else {
        updatedData[selectedTableIndex].tableList = [
          ...updatedData[selectedTableIndex].tableList,
          tableDataToRelatedTableData({ tableData: innerBelongsToObj }),
        ];
      }
    }
    setRelatedTableList(updatedData);
  };
  return (
    <div className={styles.tablesSelectionOuterContainer}>
      <div className={styles.tablesSelectionTitle}>{mls('Choose Connected Tables')}:</div>
      <button
        className='primaryButton secondaryButtonColor'
        onClick={() => handleIsSelectedTablePopup()}
      >
        {mls('Select Tables')}
      </button>
      {isSelectedTablePopup ? (
        <PopUpDivChild
          initialValue={isSelectedTablePopup}
          setPopUp={handleIsSelectedTablePopup}
          popupTitle={mls('Connected Tables')}
        >
          <div className={styles.tablesSelectionContainer}>
            {React.Children.toArray(
              Object.keys(connectedTablesData).map((connectedTableKey) => {
                const connectedTableFinalData =
                  connectedTablesData[connectedTableKey as keyof typeof connectedTablesData];

                const selectedTableIndex = (relatedTableList ?? []).findIndex(
                  (connectedTableElem: any) => {
                    const finalTableID = getFinalTableID({
                      tableID: connectedTableElem?.tableID,
                      relationType: connectedTableElem?.relationType,
                      relationID: connectedTableElem?.relationID,
                    });
                    return finalTableID === connectedTableKey;
                  }
                );
                const isSelected = selectedTableIndex > -1;
                const handleSelection = () => {
                  if (componentSchema?.table === connectedTableKey) {
                    return;
                  }

                  let udpatedConnectedTable = relatedTableList ?? [];

                  if (isSelected) {
                    udpatedConnectedTable = (relatedTableList ?? []).filter(
                      (connectedTableElem: any) => {
                        const finalTableID = getFinalTableID({
                          tableID: connectedTableElem?.tableID,
                          relationType: connectedTableElem?.relationType,
                          relationID: connectedTableElem?.relationID,
                        });
                        return finalTableID !== connectedTableKey;
                      }
                    );
                    if (
                      connectedTableFinalData?.relationType === relationTypeObjects.belongsTo.key
                    ) {
                      const tableID = connectedTableFinalData?.tableID;
                      const relationName = connectedTableFinalData?.relationName;
                      const columnName = getIDColumnName(tableID, relationName);
                      const mainTableIndex = (relatedTableList ?? []).findIndex(
                        (connectedTableElem: any) =>
                          connectedTableElem?.tableID === componentSchema?.table
                      );
                      if (mainTableIndex >= 0) {
                        const copyUdpatedConnectedTable = Array.from(udpatedConnectedTable ?? []);
                        const [mainTable] = copyUdpatedConnectedTable.splice(mainTableIndex, 1);
                        const mainTableDefalutView = (mainTable?.defaultView ?? [])?.filter(
                          (connectedTableElem: any) => connectedTableElem?.columnName !== columnName
                        );
                        const udpatedMainTable = {
                          ...mainTable,
                          defaultView: mainTableDefalutView,
                        };
                        copyUdpatedConnectedTable.splice(mainTableIndex, 0, udpatedMainTable);
                        udpatedConnectedTable = copyUdpatedConnectedTable;
                      }
                    }
                  } else {
                    const tableDataElem = tableDataToRelatedTableData({
                      tableData: connectedTablesData[connectedTableKey],
                    });
                    let innerTableList: any[] = [];
                    Object.keys(
                      connectedTablesData?.[connectedTableKey]?.innerBelongsToTable ?? {}
                    ).forEach((innerBelongsToElemKey) => {
                      const innerBelongsToElem =
                        connectedTablesData?.[connectedTableKey]?.innerBelongsToTable[
                          innerBelongsToElemKey
                        ];
                      const tableDataElem = tableDataToRelatedTableData({
                        tableData: innerBelongsToElem,
                      });
                      innerTableList = [...innerTableList, tableDataElem];
                    });
                    udpatedConnectedTable = [
                      ...udpatedConnectedTable,
                      { ...tableDataElem, tableList: innerTableList },
                    ];
                    if (
                      connectedTableFinalData?.relationType === relationTypeObjects.belongsTo.key
                    ) {
                      const tableID = connectedTableFinalData?.tableID;
                      const relationName = connectedTableFinalData?.relationName;
                      const columnName = getIDColumnName(tableID, relationName);

                      const orignalMainTable = connectedTablesData[componentSchema?.table];
                      const belongsColumn = (orignalMainTable?.columnSchema ?? []).find(
                        (colElem: any) => colElem?.columnName === columnName
                      );
                      if (belongsColumn) {
                        const mainTableIndex = (relatedTableList ?? []).findIndex(
                          (connectedTableElem: any) =>
                            connectedTableElem?.tableID === componentSchema?.table
                        );
                        if (mainTableIndex >= 0) {
                          const copyUdpatedConnectedTable = Array.from(udpatedConnectedTable ?? []);
                          const [mainTable] = copyUdpatedConnectedTable.splice(mainTableIndex, 1);
                          const udpatedMainTable = {
                            ...mainTable,
                            defaultView: [...(mainTable?.defaultView ?? []), belongsColumn],
                          };
                          copyUdpatedConnectedTable.splice(mainTableIndex, 0, udpatedMainTable);
                          udpatedConnectedTable = copyUdpatedConnectedTable;
                        }
                      }
                    }
                  }

                  setRelatedTableList(udpatedConnectedTable);
                };
                if (componentSchema?.table === connectedTableKey) {
                  return <></>;
                }
                return (
                  <div className='border border-black rounded mb-2 me-2'>
                    <div
                      className={`fw-bold ${styles.columnSelectionDiv} ${
                        componentSchema?.table === connectedTableKey
                          ? styles.columnSelectionDivDisable
                          : ''
                      }`}
                      onClick={() => handleSelection()}
                    >
                      <SlideSwitch
                        isChecked={isSelected}
                        setIsChecked={handleSelection}
                        selectedDisable={componentSchema?.table === connectedTableKey}
                        stopPropagation={true}
                      />
                      {connectedTableFinalData?.name ?? connectedTableFinalData?.tableID}
                      &nbsp; ({' '}
                      {specialDataTypeName[
                        connectedTableFinalData?.relationType as keyof typeof specialDataTypeName
                      ]?.name ?? connectedTableFinalData?.relationType}{' '}
                      ) &nbsp;
                    </div>
                    {/* {isSelected &&
                      Object.keys(connectedTableFinalData.innerBelongsToTable || {}).map(
                        (innerBelongsToKey: string) => {
                          const innerBelongsToObj =
                            connectedTableFinalData.innerBelongsToTable[innerBelongsToKey];
                          const innerBelongsToIndex = (
                            relatedTableList[selectedTableIndex]?.tableList || []
                          ).findIndex((innerSelectedTable: any) => {
                            const finalTableID = getFinalTableID({
                              tableID: innerSelectedTable?.tableID,
                              relationType: innerSelectedTable?.relationType,
                              relationID: innerSelectedTable?.relationID,
                            });
                            return finalTableID === innerBelongsToKey;
                          });
                          const isInnerSelected = innerBelongsToIndex > -1;
                          return (
                            <div
                              className={`ms-4 ${styles.columnSelectionDiv} ${
                                componentSchema?.table === connectedTableKey
                                  ? styles.columnSelectionDivDisable
                                  : ''
                              }`}
                              onClick={() =>
                                handleInnerSelections({
                                  innerBelongsToKey,
                                  innerBelongsToObj,
                                  selectedTableIndex,
                                  innerBelongsToIndex,
                                })
                              }
                            >
                              <SlideSwitch
                                isChecked={isInnerSelected}
                                setIsChecked={() =>
                                  handleInnerSelections({
                                    innerBelongsToKey,
                                    innerBelongsToObj,
                                    selectedTableIndex,
                                    innerBelongsToIndex,
                                  })
                                }
                                stopPropagation={true}
                                selectedDisable={componentSchema?.table === connectedTableKey}
                              />
                              {innerBelongsToObj?.name ?? innerBelongsToObj?.tableID}
                              &nbsp; ({' '}
                              {specialDataTypeName[
                                innerBelongsToObj?.relationType as keyof typeof specialDataTypeName
                              ]?.name ?? innerBelongsToObj?.relationType}{' '}
                              ) &nbsp;
                            </div>
                          );
                        }
                      )} */}
                  </div>
                );
              })
            )}
          </div>
        </PopUpDivChild>
      ) : null}
    </div>
  );
};

export default MainConnectedTableSelection;
