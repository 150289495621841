import React from 'react';
import styles from './aiCustomizationEditorContainer.module.scss';
import { MdOutlineDragHandle } from 'react-icons/md';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { mls } from 'lib/multilanguagesupport';

interface moreActionContainerRightProps {
  moreAction: any;
  setMoreAction: Function;
  componentSchema: any;
}
const MoreActionContainerRight = ({
  moreAction,
  setMoreAction,
  componentSchema,
}: moreActionContainerRightProps) => {
  const handleDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }
    const reorderedMoreActionList = Array.from(moreAction);
    const [movedMoreActionList] = reorderedMoreActionList.splice(result.source.index, 1);
    reorderedMoreActionList.splice(result.destination.index, 0, movedMoreActionList);
    setMoreAction(reorderedMoreActionList);
  };
  return (
    <div className={styles.moreActionContainerRight}>
      <div className={styles.selectedActionsTitle}>{mls('Action Sequence')}</div>
      <div className={styles.moreActionDraggableOuterContainer}>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId='droppable-1'>
            {(provided) => (
              <div
                className='accordion'
                ref={provided.innerRef}
                id='kt_accordion_1'
                {...provided.droppableProps}
              >
                <div className={styles.moreActionDraggableContainer}>
                  {React.Children.toArray(
                    (moreAction ?? []).map((moreActionElem: any, templateIndex: number) => {
                      const actionElemData: any = getActionElemData({
                        actionElem: moreActionElem,
                        PDFTemplate: componentSchema?.PDFTemplate ?? {},
                        emailTemplate: componentSchema?.emailTemplate ?? {},
                      });
                      if (actionElemData.skip) {
                        return <></>;
                      }
                      const templateDataElem = actionElemData?.templateData;
                      return (
                        <Draggable
                          key={templateDataElem?.templateKey}
                          draggableId={templateDataElem?.templateKey}
                          index={templateIndex}
                        >
                          {(providedDrag: any, _: any) => (
                            <div
                              ref={providedDrag.innerRef}
                              {...providedDrag.draggableProps}
                              key={templateDataElem?.templateKey}
                            >
                              <div className={styles.moreActionDraggableElem}>
                                <div
                                  className={styles.dragIconOuter}
                                  {...providedDrag?.dragHandleProps}
                                >
                                  <MdOutlineDragHandle className={styles.dragIcon} />
                                </div>
                                {templateDataElem?.title} ( {templateDataElem?.type} )
                              </div>
                            </div>
                          )}
                        </Draggable>
                      );
                    })
                  )}
                  {provided.placeholder}
                </div>
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </div>
  );
};

export default MoreActionContainerRight;

const getActionElemData = ({
  actionElem,
  emailTemplate,
  PDFTemplate,
}: {
  actionElem: any;
  emailTemplate: any;
  PDFTemplate: any;
}) => {
  if (actionElem?.type === actionButtonType.emailType.key) {
    const templateData = emailTemplate?.[actionElem?.templateKey];
    if (templateData) {
      const templateDataElem = {
        title: templateData?.title,
        templateKey: actionElem?.templateKey,
        type: actionElem?.type,
      };
      return {
        skip: false,
        templateData: templateDataElem,
      };
    }
    return { skip: true };
  }
  if (actionElem?.type === actionButtonType.pdfType.key) {
    const templateData = PDFTemplate?.[actionElem?.templateKey];
    if (templateData) {
      const templateDataElem = {
        title: templateData?.title,
        templateKey: actionElem?.templateKey,
        type: actionElem?.type,
      };
      return {
        skip: false,
        templateData: templateDataElem,
      };
    }
    return { skip: true };
  }
  return { skip: true };
};

const actionButtonType = {
  codeType: {
    key: 'codeType',
    name: 'Code Type',
  },
  pdfType: {
    key: 'pdfType',
    name: 'PDF Type',
  },
  emailType: {
    key: 'emailType',
    name: 'Email Type',
  },
};
