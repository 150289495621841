import { useSelector } from 'react-redux';
import reduxConstants from '../redux/reduxConstants';
import { useEffect, useState } from 'react';
// import { supistaApiGet } from 'app/network/apiConnections';
import { _supistaApiGet } from 'lib/server-connection/connections';
import useLogout from 'lib/auth-ui/hooks/useLogout';

function useAuthenticate() {
  const { logout } = useLogout();
  const [sessionData, setSession] = useState({});
  const authData = useSelector(
    (state) => state[reduxConstants.STORE_NAME][reduxConstants.config.LOGIN]
  );

  useEffect(() => {
    const fetchSessionData = async () => {
      try {
        const response = await _supistaApiGet(`validateUser/supista`);
          setSession(response);
        
      } catch (error) {
        console.error('Error fetching session data:', error);
      }
    };

    fetchSessionData();
  }, [authData]);

  if (authData?.isLoggedin === true) {
    const isSameUser = sessionData?.appsConnected?.[0] === authData?.appsConnected?.[0];
    // logout if session user does not match user in redux
    if (sessionData?.appsConnected?.length > 0 && !isSameUser) {
      logout(authData?.appsConnected || []);
      return false;
    }
    return true;
  }
  return false;
}

export default useAuthenticate;
