import { relationTypeObjects } from '../../data/appComponentData';
import { tableDataToRelatedTableData } from '../../utils/componentSettingFunction';

interface handleMainTableTypeColumnSelectionPros {
  relatedTable: { [key: string]: any };
  relatedTableList: any[];
  columnData: { [key: string]: any };
  connectedTablesData: { [key: string]: any };
  isSelected: boolean;
  updateRelatedTable: Function;
}

export const handleMainTableTypeColumnSelection = ({
  relatedTable,
  relatedTableList,
  columnData,
  connectedTablesData,
  isSelected,
  updateRelatedTable,
}: handleMainTableTypeColumnSelectionPros) => {
  let newDefaultView = relatedTable?.defaultView ?? [];
  let udpatedConnectedTable = relatedTableList ?? [];
  if (isSelected) {
    newDefaultView = (relatedTable?.defaultView ?? []).filter(
      (columnNameElem: any) => columnNameElem?.columnName !== (columnData?.columnName ?? '')
    );
    if (columnData?.dataType === relationTypeObjects.belongsTo.key) {
      udpatedConnectedTable = (relatedTableList ?? []).filter(
        (connectedTableElem: any) => connectedTableElem?.relationID !== columnData?.relationId
      );
    }
  } else {
    newDefaultView = [
      ...(relatedTable?.defaultView ?? []),
      {
        columnName: columnData?.columnName ?? '',
        filter: {},
        name: columnData?.name ?? '',
        description: '',
      },
    ];
    if (columnData?.dataType === relationTypeObjects.belongsTo.key) {
      const connectedTableFinalData: any = Object.values(connectedTablesData ?? {})?.find(
        (tableData: any) => tableData?.relationID === columnData?.relationId
      );
      if (connectedTableFinalData) {
        const newTableData = tableDataToRelatedTableData({
          tableData: connectedTableFinalData,
        });
        udpatedConnectedTable = [...(udpatedConnectedTable ?? []), newTableData];
      }
    }
  }
  updateRelatedTable(
    {
      ...relatedTable,
      defaultView: newDefaultView,
    },
    udpatedConnectedTable
  );
};
interface handleColumnSelectionPros {
  relatedTable: { [key: string]: any };
  relatedTableList: any[];
  columnData: { [key: string]: any };
  selectedConnectedTable: { [key: string]: any };
  isSelected: boolean;
  updateRelatedTable: Function;
}
export const handleColumnSelection = ({
  relatedTable,
  relatedTableList,
  columnData,
  selectedConnectedTable,
  isSelected,
  updateRelatedTable,
}: handleColumnSelectionPros) => {
  let newDefaultView = relatedTable?.defaultView ?? [];
  let newInnerTableList = relatedTable?.tableList ?? [];
  let udpatedConnectedTable = relatedTableList ?? [];
  if (isSelected) {
    newDefaultView = (relatedTable?.defaultView ?? []).filter(
      (columnNameElem: any) => columnNameElem?.columnName !== (columnData?.columnName ?? '')
    );
    if (columnData?.dataType === relationTypeObjects.belongsTo.key) {
      newInnerTableList = (relatedTable?.tableList ?? []).filter(
        (connectedTableElem: any) => connectedTableElem?.relationID !== columnData?.relationId
      );
    }
  } else {
    newDefaultView = [
      ...(relatedTable?.defaultView ?? []),
      {
        columnName: columnData?.columnName ?? '',
        filter: {},
        name: columnData?.name ?? '',
        description: '',
      },
    ];
    if (columnData?.dataType === relationTypeObjects.belongsTo.key) {
      const connectedTableFinalData: any = Object.values(
        selectedConnectedTable?.innerBelongsToTable ?? {}
      )?.find((tableData: any) => tableData?.relationID === columnData?.relationId);
      if (connectedTableFinalData) {
        const newTableData = tableDataToRelatedTableData({
          tableData: connectedTableFinalData,
        });
        newInnerTableList = [...(newInnerTableList ?? []), newTableData];
      }
    }
  }
  updateRelatedTable(
    {
      ...relatedTable,
      defaultView: newDefaultView,
      tableList: newInnerTableList,
    },
    udpatedConnectedTable
  );
};

interface handleInnerTableColumnSelectionPros {
  relatedTable: { [key: string]: any };
  relatedTableList: any[];
  columnData: { [key: string]: any };
  connectedTablesData: { [key: string]: any };
  isSelected: boolean;
  updateRelatedTable: Function;
  innerTableElem: { [key: string]: any };
  innerTableIndex: number;
}
export const handleInnerTableColumnSelection = ({
  relatedTable,
  columnData,
  isSelected,
  updateRelatedTable,
  innerTableElem,
  innerTableIndex,
}: handleInnerTableColumnSelectionPros) => {
  let newDefaultView = innerTableElem?.defaultView ?? [];
  if (isSelected) {
    newDefaultView = (innerTableElem?.defaultView ?? []).filter(
      (columnNameElem: any) => columnNameElem?.columnName !== (columnData?.columnName ?? '')
    );
  } else {
    newDefaultView = [
      ...(innerTableElem?.defaultView ?? []),
      {
        columnName: columnData?.columnName ?? '',
        filter: {},
        name: columnData?.name ?? '',
        description: '',
      },
    ];
  }
  const newTableList = Array.from(relatedTable?.tableList ?? []);
  const [selectedItem]: any[] = newTableList.splice(innerTableIndex, 1);
  const newSelectedTabeList = {
    ...selectedItem,
    defaultView: newDefaultView,
  };
  newTableList.splice(innerTableIndex, 0, newSelectedTabeList);
  updateRelatedTable({
    ...relatedTable,
    tableList: newTableList,
  });
};
