import React, { useState } from 'react';
// import styles from '../ComponentSettingWrapper.module.scss';
import styles from './queryGenerator.module.scss';
import { mls } from 'lib/multilanguagesupport';
import { Button, Form } from 'react-bootstrap';
import { CircularProgress } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { _supistaApiPost } from 'lib/server-connection/connections';
import AIToQuery from './AIToQuery';
import { FaHistory } from 'react-icons/fa';
import useStoreSchema from 'lib/reusable-components/reusableUtils/commons/useStoreSchema';
import reduxConstants from 'lib/reusable-components/reusableUtils/redux/reduxConstants';
import ExpandingTextarea from 'lib/reusable-components/Components/ExpandingTextarea/ExpandingTextarea';
import { erpAllDataType, erpSimpleDataType } from 'lib/erp-data-table/components/data/erpData';
import { buttonTypeObject, crudTypeButtonObject } from '../data/appComponentData';
import { getDefaultCodeForFirstType } from '../utils/componentSettingFunction';

interface queryGeneratorProps {
  newConnectedTableData: any;
  selectedCode: any;
  appID: string;
  code: string;
  handleCodeChange: Function;
  activeButtonKey: string;
  buttonType: string;
  selectedTable: string;
  selectedParentId: any;
  connectedTablesData: { [key: string]: any };
  selectedId: any;
  componentSchema: any;
}
const QueryGenerator = ({
  newConnectedTableData,
  appID,
  handleCodeChange,
  code,
  activeButtonKey,
  selectedCode,
  buttonType,
  selectedTable,
  connectedTablesData,
  selectedParentId,
  selectedId,
  componentSchema,
}: queryGeneratorProps) => {
  const [query, setQuery] = useState('');
  const [isAiQueryLoading, setIsAiQueryLoading] = useState(false);
  const [isAIQuaryPopup, setIsAIQuaryPopup] = useState(false);
  const [aiQuaryData, setAiQuaryData] = useState('');
  const [useCurrentCode, setUseCurrentCode] = useState(true);
  const appSchema = useStoreSchema(reduxConstants.STORE_NAME, reduxConstants.config.APP_SCHEMA);
  const appDatatable = appSchema?.appDatatable ?? {};
  const handleQueryChange = (e: any) => {
    setQuery(e.target.value);
  };
  const getTableList = () => {
    let tableList: any[] = [];
    const tableSchema = selectedCode?.tableSchema ?? [];
    const mainTableName = newConnectedTableData?.tableName?.[0];
    tableSchema.forEach(({ tableID, tableUsed }: { tableID: string; tableUsed: string }) => {
      const tableElem = appDatatable?.[tableID];
      if (tableElem) {
        let columns = '';
        (tableElem?.columnSchema ?? []).forEach((columnElem: any, index: number) => {
          let options = '';
          if (columnElem?.dataType === erpAllDataType.select.key) {
            (columnElem?.options?.selectMenu ?? []).forEach(
              (selectMenuElem: any, index: number) => {
                if (index === 0) {
                  options = ` <${selectMenuElem?.value}`;
                } else {
                  options = `${options}, ${selectMenuElem?.value}`;
                }
              }
            );
            if (options) {
              options = `${options}>`;
            }
          }
          const newDatatype =
            erpAllDataType?.[columnElem?.dataType as keyof typeof erpAllDataType]?.simpleDataType
              ?.key ?? erpSimpleDataType.text.key;
          if (index === 0) {
            columns = `${columnElem?.columnName} (${newDatatype})${options}`;
          } else {
            columns = `${columns}, ${columnElem?.columnName} (${newDatatype})${options}`;
          }
        });
        tableList = [
          ...tableList,
          {
            tableName: tableElem?.tableID,
            columns: columns,
            [`${mainTableName} - ${tableElem?.tableID} - relations`]: tableUsed,
          },
        ];
      }
    });
    return { tableList };
  };
  const handleAiRequest = async (e: any) => {
    e.preventDefault();
    const baseUrl = `aiQuery/${appID}`;
    const { tableList } = getTableList();
    let columnString: string = '';
    const selectedTableData = connectedTablesData[selectedTable];
    (selectedTableData?.columnSchema || []).forEach((columnElem: any, index: number) => {
      const newDatatype =
        erpAllDataType?.[columnElem?.dataType as keyof typeof erpAllDataType]?.simpleDataType
          ?.key ?? erpSimpleDataType.text.key;

      if (index === 0) {
        columnString = `${columnElem?.columnName} (${newDatatype})`;
      } else {
        columnString = `${columnString}, ${columnElem?.columnName} (${newDatatype})`;
      }
    });
    let payloadData: { [key: string]: any } = {
      queryArray: [
        {
          promptType: 'CRUD',
          query: query,
          apiType: buttonType === 'actionButton' ? 'PUT' : activeButtonKey,
        },
      ],
      code: '',
      data: {
        tableList: tableList,
        selectedTable: {
          columns: columnString,
          tableName: selectedTableData?.tableID,
        },

        // mainTable: {
        //   columns: columnString,
        //   tableName: newConnectedTableData?.tableName?.[0] || newConnectedTableData?.tableName,
        // },
      },
      // data: {
      //   tableName: newConnectedTableData?.tableName,
      //   columnSchema: newConnectedTableData?.columnSchema,
      // },
    };
    const isRealationTypeIsNotMain = selectedTableData?.relationType !== 'MAIN';
    if (isRealationTypeIsNotMain) {
      payloadData = {
        ...payloadData,
        data: {
          ...(payloadData?.data ?? {}),
          parentTable: componentSchema?.table,
          relationType: selectedTableData?.relationType,
        },
      };
    }
    if (useCurrentCode) {
      const finalCode = getDefaultCodeForFirstType({
        selectTableName: selectedTable,
        connectedTablesData,
        selectedParentId,
        selectedId,
        currentCode: code,
        buttonType,
        activeButtonKey,
      });
      payloadData = {
        ...payloadData,
        code: finalCode,
      };
    }
    setIsAiQueryLoading(true);
    const componentRes = await _supistaApiPost(baseUrl, { data: payloadData });
    if (componentRes?.[0]) {
      setIsAIQuaryPopup(true);
      setAiQuaryData(componentRes[0]?.response ?? '');
      setIsAiQueryLoading(false);
    } else {
      setIsAiQueryLoading(false);
    }
  };

  return (
    <div>
      {isAIQuaryPopup ? (
        <AIToQuery
          isAIQuaryPopup={isAIQuaryPopup}
          aiQuaryData={aiQuaryData}
          setIsAIQuaryPopup={setIsAIQuaryPopup}
          handleCodeChange={handleCodeChange}
          code={code}
        />
      ) : null}
      <Form className={styles.queryGenerator} onSubmit={handleAiRequest}>
        <div className={styles.queryHistoryButton} onClick={() => setIsAIQuaryPopup(true)}>
          <FaHistory />
        </div>
        {/* <div
          className={styles.useCurrentCodeTooggle}
          onClick={() => setUseCurrentCode(!useCurrentCode)}
          title={mls('Use Current Code!')}
        >
          <SlideSwitch isChecked={useCurrentCode} setIsChecked={() => {}} />
        </div> */}
        <Form.Group controlId='queryInput' className={styles.queryInput}>
          <div className={styles.queryInputInner}>
            <ExpandingTextarea
              // placeholder={`Generate Code using AI ...`}
              placeholder={mls(`Write steps to generate code...`)}
              name={'queryInput'}
              value={query}
              onChange={handleQueryChange}
            />
          </div>
        </Form.Group>
        <Button variant='primary' onClick={handleAiRequest} className={styles.generateButton}>
          {isAiQueryLoading ? (
            <>
              <CircularProgress color='inherit' size={12} />
              &nbsp;
            </>
          ) : (
            <SendIcon fontSize={'small'} />
          )}
          &nbsp;
          {mls('Send')}
        </Button>
      </Form>
    </div>
  );
};

export default QueryGenerator;
