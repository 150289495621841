import React, { useEffect, useRef, useState } from 'react';

import { mls } from 'lib/multilanguagesupport';
import useDebounce from 'lib/reusable-components/hooks/useDebounce';
import useUpdateEffect from 'lib/reusable-components/hooks/useUpdateEffect';
// import { defaultColors } from './defaultColors';
import './chartStyle.scss';
import styles from './solidColorSelecor.module.scss';
import { defaultColorPalettes } from './defaultColors';

const SolidColorSelector = (solidColorProps) => {
  const {
    color,
    setColor,
    isPalette = true,
    resetColor = false,
    inputClassName = '',
    mainStyle = {},
    disabled = false,
    selectColorTitle = isPalette ? mls('Select Manually') : mls('Select Color'),
    colorPalette = defaultColorPalettes,
  } = solidColorProps;
  const [selectedColor, setSelectedColor] = useState(color?.color ?? colorPalette?.[0]);
  const debounceSelectedColor = useDebounce(selectedColor);
  const [selectedOpacity, setSelectedOpacity] = useState(color?.opacity ?? 100);
  const debounceSelectedOpacity = useDebounce(selectedOpacity);
  const handleColor = (e) => {
    if (e.target.value.length >= 8) {
      return;
    }
    if (e.target.value.length === 0) {
      return;
    }
    setSelectedColor(e.target.value.toUpperCase());
  };
  const handleOpacity = (e) => {
    if (e.target.value > 100) {
      return;
    }
    setSelectedOpacity(e.target.value);
  };

  const paletteOnClick = (elem) => {
    setSelectedColor(elem);
    setSelectedOpacity(100);
  };

  useUpdateEffect(() => {
    if (!color) return;
    setColor({ ...color, color: selectedColor, opacity: selectedOpacity });
  }, [debounceSelectedColor, debounceSelectedOpacity]);
  useUpdateEffect(() => {
    if (!color) return;
    setSelectedColor(color.color);
    setSelectedOpacity(color.opacity);
  }, [resetColor]);

  return (
    <div className={`solidColorSelctor colorSelector ${inputClassName}`} style={mainStyle}>
      {isPalette ? (
        <>
          <div className='titleDiv'>{mls('Colour Palette')}</div>
          <div className='colorPalettesOuterContainer'>
            <div className='colorPalettesInnerContainer'>
              {React.Children.toArray(
                (colorPalette ?? []).map((elem) => {
                  return (
                    <div
                      className='palette'
                      style={{ backgroundColor: elem }}
                      onClick={() => paletteOnClick(elem)}
                    ></div>
                  );
                })
              )}
            </div>
          </div>
        </>
      ) : null}
      {isPalette ? <div className='titleDiv'>{selectColorTitle}</div> : null}
      <div className='colorInputDiv' style={isPalette ? {} : { marginBottom: 0 }}>
        <input type='text' className='colorHashInput' value={selectedColor} onChange={(e) => {}} />
        <input
          type='color'
          value={selectedColor}
          className='inputTypeColorPicker'
          onChange={(e) => handleColor(e)}
          disabled={disabled}
          style={{
            padding: 0,
            width: '100%',
          }}
        />
        <div
          className='colorDropperInput'
          style={{ backgroundColor: selectedColor, opacity: selectedOpacity / 100 }}
        >
          <input
            type='color'
            disabled={disabled}
            value={selectedColor}
            onChange={(e) => handleColor(e)}
          />
        </div>
      </div>
      {/* <div className='titleDiv'>
        {mls('Opacity')}
        <span>{selectedOpacity}%</span>
      </div>
      <Slider
        size='small'
        className='opacityRange'
        value={selectedOpacity}
        onChange={(e) => handleOpacity(e)}
        defaultValue={selectedOpacity}
        aria-label='Small'
        valueLabelDisplay='auto'
      /> */}
    </div>
  );
};

export default SolidColorSelector;

// function opacitySchema(opacity ,selectedColor) {
//     if (opacity == 100){
//         return selectedColor
//     }
//     var newOpacity = (opacity < 10) ? '0' + opacity.toString() : opacity.toString();
//     return selectedColor+newOpacity
// }

// export const SolidColorPopupWrapper = ({ color, setColor }) => {
export const SolidColorPopupWrapper = (props) => {
  const [isColorPopup, setIsColorPopup] = useState(false);

  const wrapperRef = useRef(null);
  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        //NEW
        const scrollCheckFunction = () => {
          const path = event.composedPath();
          const e = event;
          let isScrollClick = false;
          let scrollStart = { x: 0, y: 0 };
          let clickedElement = null;
          for (let element of path) {
            if (element instanceof HTMLElement) {
              const { clientWidth, clientHeight, scrollWidth, scrollHeight } = element;
              const rect = element.getBoundingClientRect();

              // Check for vertical scrollbar
              const verticalScrollbar =
                scrollHeight > clientHeight &&
                e.clientX > rect.right - 17 &&
                e.clientX <= rect.right;

              // Check for horizontal scrollbar
              const horizontalScrollbar =
                scrollWidth > clientWidth &&
                e.clientY > rect.bottom - 17 &&
                e.clientY <= rect.bottom;

              if (verticalScrollbar || horizontalScrollbar) {
                isScrollClick = true;
                break;
              }
            }
          }
          return { isScrollClick, scrollStart, clickedElement };
        };
        const { isScrollClick } = scrollCheckFunction();
        if (isScrollClick) {
          return;
        }
        setIsColorPopup(false);
      }
    }
    if (isColorPopup) {
      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wrapperRef, isColorPopup]);

  return (
    <div ref={wrapperRef} className={styles.SolidColorPopupWrapper}>
      <div
        onClick={() => {
          setIsColorPopup(true);
        }}
        className={styles.solidColorPopupWrapperInner}
        key={props?.color?.color ?? ''}
      >
        <div
          className={styles.solidColorPopupWrapperInnerPopupButton}
          onClick={() => setIsColorPopup(true)}
        ></div>
        <SolidColorSelector
          {...props}
          setColor={(e) => {}}
          isPalette={false}
          mainStyle={{ width: '100%' }}
          disabled={true}
          // inputClassName={clsx('form-control form-control-lg ')}
        />
      </div>
      {isColorPopup ? (
        <div className={styles.solidColorPopupContainer}>
          <SolidColorSelector {...props} />
        </div>
      ) : null}
    </div>
  );
};
