import React from 'react';
import { Accordion } from 'react-bootstrap';

import SlideSwitch from 'lib/reusable-components/Components/SlideSwitch/SlideSwitch';
import styles from '../../ComponentSettingWrapper.module.scss';

import PopUpDivChild from 'lib/reusable-components/PopUpDivChild/PopUpDivChild';
import {
  handleMainTableTypeColumnSelection,
  handleColumnSelection,
  handleInnerTableColumnSelection,
} from './additionalSettingsUtils';
import { getFinalTableID } from '../../utils/componentSettingFunction';
interface selectedColumnPopupProps {
  isSelectedColumnPopup: boolean;
  handleIsSelectedColumnPopup: Function;
  relatedTable: any;
  selectedConnectedTable: any;
  relatedTableList: any;
  tableID: string;
  relationType: string;
  tableData: any;
  updateRelatedTable: Function;
  setRelatedTableList: Function;
  connectedTablesData: any;
}
const SelectedColumnPopup = ({
  isSelectedColumnPopup,
  handleIsSelectedColumnPopup,
  relatedTable,
  selectedConnectedTable,
  relatedTableList,
  tableID,
  relationType,
  tableData,
  updateRelatedTable,
  connectedTablesData,
}: selectedColumnPopupProps) => {
  const isMainTable = relatedTable?.relationType === 'MAIN';

  return (
    <div className={styles.tablesSelectionOuterContainer}>
      <PopUpDivChild
        initialValue={isSelectedColumnPopup}
        setPopUp={handleIsSelectedColumnPopup}
        popupTitle={`${tableData?.name ?? tableID} (${relationType})`}
      >
        <div className={styles.tablesSelectionContainer}>
          {React.Children.toArray(
            (tableData?.columnSchema ?? []).map((columnData: any) => {
              const isStartWithD3 = (columnData?.columnName ?? '').startsWith('__d3__');
              if (isStartWithD3) {
                return null;
              }
              const isSelected = (relatedTable?.defaultView ?? []).some(
                (columnNameElem: any) =>
                  columnNameElem?.columnName === (columnData?.columnName ?? '')
              );

              return (
                <div
                  className={`${styles.columnSelectionDiv} `}
                  onClick={() =>
                    isMainTable
                      ? handleMainTableTypeColumnSelection({
                          relatedTable,
                          relatedTableList,
                          columnData,
                          connectedTablesData,
                          isSelected,
                          updateRelatedTable,
                        })
                      : handleColumnSelection({
                          relatedTable,
                          relatedTableList,
                          columnData,
                          selectedConnectedTable: selectedConnectedTable,
                          isSelected,
                          updateRelatedTable,
                        })
                  }
                >
                  <SlideSwitch isChecked={isSelected} setIsChecked={() => {}} />
                  {columnData?.name ?? columnData?.columnName} ({columnData?.dataType})
                </div>
              );
            })
          )}
        </div>
        <Accordion>
          {(relatedTable?.tableList || []).map((innerTableElem: any, innerTableIndex: number) => {
            const finalTableID = getFinalTableID({
              tableID: innerTableElem?.tableID,
              relationType: innerTableElem?.relationType,
              relationID: innerTableElem?.relationID,
            });
            const innerTableData = selectedConnectedTable?.innerBelongsToTable?.[finalTableID];
            // console.log(innerTableData);
            // console.log(selectedConnectedTable);
            // console.log(finalTableID);

            if (!innerTableData) {
              return null;
            }
            return (
              <Accordion.Item
                eventKey={innerTableElem.tableID}
                className='selectedInnerTableAccordion'
              >
                <Accordion.Header>
                  {innerTableElem.screenName ?? innerTableElem.tableID}
                </Accordion.Header>
                <Accordion.Body className={styles.tablesSelectionContainer}>
                  {React.Children.toArray(
                    (innerTableData?.columnSchema ?? []).map((columnData: any) => {
                      const isStartWithD3 = (columnData?.columnName ?? '').startsWith('__d3__');
                      if (isStartWithD3) {
                        return null;
                      }
                      const isSelected = (innerTableElem?.defaultView ?? []).some(
                        (columnNameElem: any) =>
                          columnNameElem?.columnName === (columnData?.columnName ?? '')
                      );
                      return (
                        <div
                          className={`${styles.columnSelectionDiv} `}
                          onClick={() =>
                            handleInnerTableColumnSelection({
                              relatedTable,
                              relatedTableList,
                              columnData,
                              connectedTablesData,
                              isSelected,
                              updateRelatedTable,
                              innerTableElem,
                              innerTableIndex,
                            })
                          }
                        >
                          <SlideSwitch isChecked={isSelected} setIsChecked={() => {}} />
                          {columnData?.name ?? columnData?.columnName} ({columnData?.dataType})
                        </div>
                      );
                    })
                  )}
                </Accordion.Body>
              </Accordion.Item>
            );
          })}
        </Accordion>
      </PopUpDivChild>
    </div>
  );
};

export default SelectedColumnPopup;
