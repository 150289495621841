import { erpAllDataType } from 'lib/erp-data-table/components/data/erpData';
import {
  buttonTypeObject,
  crudOpType,
  crudTypeButtonObject,
  defaultCode,
  defaultValidationCode,
} from '../data/appComponentData';
import { v4 as uuid } from 'uuid';

export const getDefaultCode = ({
  componentSchema,
  actionButtonKey,
  buttonType,
  selectedTable,
}: {
  componentSchema: { [key: string]: any };
  actionButtonKey: string;
  buttonType: string;
  selectedTable: string;
}) => {
  const codeCustomization = componentSchema?.codeCustomization ?? {};
  const selectedCode = codeCustomization?.[selectedTable];
  const crudOpData = selectedCode?.crudOp ?? {};
  const formValidationData = selectedCode?.formValidation ?? {};
  const actionButtonData = selectedCode?.actionButton ?? {};
  const cronJobData = selectedCode?.cronJob ?? {};
  if (buttonType === buttonTypeObject.CRUD.key) {
    if (crudOpData?.[actionButtonKey]?.type === crudOpType.CUSTOM.key) {
      return crudOpData?.[actionButtonKey];
    }
    const functionID = uuid();
    const updatedCrudFunction = {
      fuctionName: 'displayFunc',
      functionID: functionID,
      functionCode: defaultCode,
      params: [],
      type: crudOpType.CUSTOM.key,
    };
    return updatedCrudFunction;
  } else if (buttonType === buttonTypeObject.formValidation.key) {
    if (formValidationData?.type === crudOpType.CUSTOM.key) {
      return formValidationData;
    }
    const functionID = uuid();
    const updatedCrudFunction = {
      fuctionName: 'displayFunc',
      functionID: functionID,
      functionCode: defaultValidationCode,
      displayWhen: {},
      displayTo: [],
      title: 'New Action',
    };

    return updatedCrudFunction;
  } else if (buttonType === buttonTypeObject.actionButton.key) {
    if (actionButtonData?.[actionButtonKey]) {
      return actionButtonData?.[actionButtonKey];
    }
    const functionID = uuid();
    const updatedCrudFunction = {
      fuctionName: 'actionFunc',
      functionID: functionID,
      functionCode: defaultCode,
      displayWhen: {},
      displayTo: [],
      title: 'New Action',
    };

    return updatedCrudFunction;
  } else if (buttonType === buttonTypeObject.cronJob.key) {
    if (cronJobData?.[actionButtonKey]) {
      return cronJobData?.[actionButtonKey];
    }
    const functionID = uuid();
    const updatedCrudFunction = {
      fuctionName: 'actionFunc',
      functionID: functionID,
      functionCode: defaultCode,
      displayWhen: {},
      displayTo: [],
      title: 'New CRON Job',
    };

    return updatedCrudFunction;
  }
};
export const getDefaultInput = ({
  connectedTablesData,
  selectedTable,
  buttonType,
  parentId,
  selectedId,
  actionButtonKey,
}: {
  connectedTablesData: { [key: string]: any };
  selectedTable: string;
  buttonType: string;
  parentId: string | number;
  selectedId: string | number;
  actionButtonKey: string;
}) => {
  const selectedTableData = connectedTablesData[selectedTable];
  const isRelationID = selectedTableData?.relationType !== 'MAIN';
  const relationID = selectedTableData?.relationID;

  if (buttonType === buttonTypeObject.CRUD.key) {
    if (actionButtonKey === crudTypeButtonObject.POST.key) {
      if (isRelationID) {
        return `{
    "__d3__newData": {},
    "__d3__parentId": ${parentId ?? 0},
    "__d3__relationId": "${relationID}"
}`;
      }
      return `{
      "__d3__newData": {}
}`;
    }
    if (actionButtonKey === crudTypeButtonObject.GET.key) {
      if (isRelationID) {
        return `{
    "__d3__filterdata": {"where": {}},
    "__d3__parentId": ${parentId ?? 0},
    "__d3__relationId": "${relationID}"
}`;
      }
      return `{
      "__d3__filterdata": {"where": {}}
}`;
    }
    if (actionButtonKey === crudTypeButtonObject.PUT.key) {
      if (isRelationID) {
        return `{
    "__d3__newData": {},
    "__d3__id": ${selectedId ?? ''},
    "__d3__parentId": ${parentId ?? 0},
    "__d3__relationId": "${relationID}"
}`;
      }
      return `{
      "__d3__newData": {},
      "__d3__id": ${selectedId ?? ''}
}`;
    }
    if (actionButtonKey === crudTypeButtonObject.DELETE.key) {
      if (isRelationID) {
        return `{
    "__d3__parentId": ${parentId ?? 0},
    "__d3__id": ${selectedId ?? ''},
    "__d3__relationId": "${relationID}"
}`;
      }
      return `{
    "__d3__id": ${selectedId ?? ''}
}`;
    }
  } else if (buttonType === buttonTypeObject.formValidation.key) {
    if (isRelationID) {
      return `{
  "__d3__validateData": {},
  "__d3__parentId": ${parentId ?? ''},
  "__d3__relationId": "${relationID}"
}`;
    }
    return `{
    "__d3__validateData": {}
}`;
  } else if (buttonType === buttonTypeObject.actionButton.key) {
    if (isRelationID) {
      return `{
  "__d3__newData": {},
  "__d3__parentId": ${parentId ?? 0},
  "__d3__relationId": "${relationID}"
}`;
    }
    return `{
    "__d3__newData": {}
}`;
  } else if (buttonType === buttonTypeObject.cronJob.key) {
    if (isRelationID) {
      return `{
  "__d3__newData": {},
  "__d3__parentId": ${parentId ?? 0},
  "__d3__relationId": "${relationID}"
}`;
    }
    return `{
    "__d3__newData": {}
}`;
  }
  return '{}';
};
export const getDefaultGeneratedCode = ({
  componentSchema,
  selectedTable,
}: {
  componentSchema: { [key: string]: any };
  selectedTable: string;
}) => {
  const codeCustomization = componentSchema?.codeCustomization ?? {};
  const selectedCode = codeCustomization?.[selectedTable];
  const crudOpData = selectedCode?.crudOp ?? {};
  const formValidationData = selectedCode?.formValidation ?? {};
  const actionButtonData = selectedCode?.actionButton ?? {};
  const finalCode = {
    POST:
      crudOpData?.POST?.type === crudOpType.DEFAULT.key
        ? { functionCode: defaultCode }
        : crudOpData?.POST,
    GET:
      crudOpData?.GET?.type === crudOpType.DEFAULT.key
        ? { functionCode: defaultCode }
        : crudOpData?.GET,
    DELETE:
      crudOpData?.DELETE?.type === crudOpType.DEFAULT.key
        ? { functionCode: defaultCode }
        : crudOpData?.DELETE,
    PUT:
      crudOpData?.PUT?.type === crudOpType.DEFAULT.key
        ? { functionCode: defaultCode }
        : crudOpData?.PUT,
    [buttonTypeObject.formValidation.key]:
      formValidationData?.type === crudOpType.DEFAULT.key
        ? { functionCode: defaultCode }
        : formValidationData,
    actionButton: actionButtonData ?? {},
  };
  return finalCode;
};
export const getIDColumnName = (tableID = '', relationName = '') => {
  if (relationName) {
    return `__d3id__${tableID}Id__${relationName}`;
  }
  return `__d3id__${tableID}Id`;
};
export const getDefaultCodeForFirstType = ({
  selectTableName,
  connectedTablesData,
  selectedParentId,
  selectedId,
  currentCode,
  buttonType,
  activeButtonKey,
}: {
  selectTableName: string;
  connectedTablesData: { [key: string]: any };
  selectedParentId: any;
  selectedId: any;
  currentCode: string;
  buttonType: string;
  activeButtonKey: string;
}) => {
  const selectedTableData = connectedTablesData?.[selectTableName];
  const textColumnData = (selectedTableData?.columnSchema ?? []).find((columnElem: any) => {
    return columnElem?.dataType === erpAllDataType.text.key;
  });
  const parentId = selectedParentId;
  const relationID = selectedTableData?.relationID;
  const isRealationTypeIsNotMain = selectedTableData?.relationType !== 'MAIN';
  const codeChanged = defaultCode !== currentCode;

  if (codeChanged) {
    return currentCode;
  }
  if (
    buttonTypeObject.CRUD.key === buttonType &&
    activeButtonKey === crudTypeButtonObject.POST.key
  ) {
    const createOpDefaultCode = `
    function customizeERP({token, functionID, appID, userData},{createOp,readOp,updateOp,removeOp}) {
    // Your Code Starts Here
    const tableName = ${selectTableName};
    return new Promise(async (resolve, reject) => {
        const appData = { token, functionID, appID };
        const { _d3_newData } = data;
        const newRow = await createOp(appData, tableName, {
            _d3_newData: { ..._d3_newData,
            ${
              textColumnData?.columnName
                ? `${textColumnData?.columnName ?? 'columnName'}: "example" `
                : ''
            }
            }
            ${
              isRealationTypeIsNotMain
                ? `,_d3_parentId:${parentId ?? `''`},_d3_relationId:${relationID ?? `''`}`
                : ''
            }
            });
        return newRow
        })
}`;
    return createOpDefaultCode;
  }
  if (
    buttonTypeObject.CRUD.key === buttonType &&
    activeButtonKey === crudTypeButtonObject.PUT.key
  ) {
    const updateOpDefaultCode = `
    function customizeERP({token, functionID, appID, userData},{createOp,readOp,updateOp,removeOp}) {
    // Your Code Starts Here
    const tableName = ${selectTableName};
    return new Promise(async (resolve, reject) => {
        const appData = { token, functionID, appID };
        const { _d3_newData } = data;
        const newRow = await updateOp(appData, tableName, {
            _d3_id:${selectedId ?? `''`},
            _d3_newData: { ..._d3_newData,
            ${
              textColumnData?.columnName
                ? `${textColumnData?.columnName ?? 'columnName'}: "example" `
                : ''
            }
            }
            ${
              isRealationTypeIsNotMain
                ? `,_d3_parentId:${parentId ?? `''`},_d3_relationId:${relationID ?? `''`}`
                : ''
            }
            });
        return newRow
        })
}`;
    return updateOpDefaultCode;
  }
  if (
    buttonTypeObject.CRUD.key === buttonType &&
    activeButtonKey === crudTypeButtonObject.DELETE.key
  ) {
    const deleteOpDefaultCode = `
    function customizeERP({token, functionID, appID, userData},{createOp,readOp,updateOp,removeOp}) {
    // Your Code Starts Here
    const tableName = ${selectTableName};
    return new Promise(async (resolve, reject) => {
        const appData = { token, functionID, appID };
        const { _d3_newData } = data;
        const newRow = await removeOp(appData, tableName, {
            _d3_id:${selectedId ? `[${selectedId}]` : `[]`}
            ${
              isRealationTypeIsNotMain
                ? `,_d3_parentId:${parentId ?? `''`},_d3_relationId:${relationID ?? `''`}`
                : ''
            }
            });
        return newRow
        })
}`;
    return deleteOpDefaultCode;
  }
  if (
    buttonTypeObject.CRUD.key === buttonType &&
    activeButtonKey === crudTypeButtonObject.GET.key
  ) {
    const readOpDefaultCode = `
    function customizeERP({token, functionID, appID, userData},{createOp,readOp,updateOp,removeOp}) {
    // Your Code Starts Here
    const tableName = ${selectTableName};
    return new Promise(async (resolve, reject) => {
        const appData = { token, functionID, appID };
        const rowsData = await readOp(appData, tableName, {
            _d3_filterdata: { where: {
             ${
               textColumnData?.columnName
                 ? `${textColumnData?.columnName ?? 'columnName'}: "example" `
                 : ''
             } } ${
      isRealationTypeIsNotMain
        ? `,_d3_parentId:${parentId ?? `''`},_d3_relationId:${relationID ?? `''`}`
        : ''
    }
            });
        return rowsData
        })
}`;
    return readOpDefaultCode;
  }
  if (buttonTypeObject.actionButton.key === buttonType) {
    const actionButtonDefaultCode = `
    function customizeERP({token, functionID, appID, userData},{createOp,readOp,updateOp,removeOp}) {
    // Your Code Starts Here
    // A sample code is written where user is using createOp function.
    const tableName = ${selectTableName};
    return new Promise(async (resolve, reject) => {
        const appData = { token, functionID, appID };
        const { _d3_newData } = data;
        const newRow = await updateOp(appData, tableName, {
            _d3_id:${selectedId ?? `''`},
            _d3_newData: { ..._d3_newData,
            ${
              textColumnData?.columnName
                ? `${textColumnData?.columnName ?? 'columnName'}: "example" `
                : ''
            }
            }
            ${
              isRealationTypeIsNotMain
                ? `,_d3_parentId:${parentId ?? `''`},_d3_relationId:${relationID ?? `''`}`
                : ''
            }
            });
        return newRow
        })
}`;
    return actionButtonDefaultCode;
  }
  return currentCode;
};
export const getTableListData = ({
  tableId,
  appDatatable,
  giveAllColumns = false,
}: {
  tableId: string;
  appDatatable: { [key: string]: any };
  giveAllColumns: boolean;
}) => {
  const connectedTablesData = getConnectedTablesNew({
    tableId: tableId,
    appDatatable,
  });
  let relatedTableList: any[] = [];
  Object.keys(connectedTablesData ?? {}).forEach((tableID) => {
    const tableDataElem = tableDataToRelatedTableData({
      tableData: connectedTablesData[tableID],
      giveAllColumns,
    });
    let innerTableList: any[] = [];
    Object.keys(connectedTablesData?.[tableID]?.innerBelongsToTable ?? {}).forEach(
      (innerBelongsToElemKey) => {
        const innerBelongsToElem =
          connectedTablesData?.[tableID]?.innerBelongsToTable[innerBelongsToElemKey];
        const tableDataElem = tableDataToRelatedTableData({
          tableData: innerBelongsToElem,
          giveAllColumns,
        });
        innerTableList = [...innerTableList, tableDataElem];
      }
    );
    relatedTableList = [...relatedTableList, { ...tableDataElem, tableList: innerTableList }];
  });
  return relatedTableList;
};
export const tableDataToRelatedTableData = ({
  tableData,
  giveAllColumns = false,
}: {
  tableData: any;
  giveAllColumns?: boolean;
}) => {
  let defaultView: any[] = [];
  (tableData?.columnSchema ?? []).forEach((elem: any) => {
    const isStartWithD3 = (elem?.columnName ?? '').startsWith('__d3__');
    if (!isStartWithD3) {
      // for belongsTo Table add only belongsTo type columns
      if (tableData?.relationType === 'belongsTo' && !giveAllColumns) {
        if (elem?.dataType === 'belongsTo') {
          defaultView = [
            ...defaultView,
            {
              columnName: elem?.columnName ?? '',
              filters: {},
              name: elem?.name ?? '',
              description: '',
            },
          ];
        }
      } else {
        // otherWise add all type of columns
        defaultView = [
          ...defaultView,
          {
            columnName: elem?.columnName ?? '',
            filters: {},
            name: elem?.name ?? '',
            description: '',
          },
        ];
      }
    }
  });
  const parentRelationID = tableData?.parentRelationID
    ? { parentRelationID: tableData?.parentRelationID }
    : {};
  if (tableData?.relationType === 'belongsTo') {
    const id = {
      columnName: 'id',
      filters: {},
      name: 'id',
      description: '',
    };
    return {
      defaultView: [id, ...defaultView],
      screenName: tableData?.screenName,
      tableID: tableData?.tableID,
      relationType: tableData?.relationType,
      relationID: tableData?.relationID,
      description: '',
      ...parentRelationID,
    };
  } else if (tableData?.relationType === 'MAIN') {
    return {
      defaultView: defaultView ?? [],
      tableID: tableData?.tableID,
      relationType: tableData?.relationType,
      // relationID: tableData?.relationID,
      ...parentRelationID,
    };
  }
  return {
    defaultView: defaultView ?? [],
    relationType: tableData?.relationType,
    tableID: tableData?.tableID,
    screenName: tableData?.screenName,
    relationID: tableData?.relationID,
    description: '',
    ...parentRelationID,
  };
};
export const getConnectedTablesNew = ({
  tableId,
  appDatatable,
}: {
  tableId: string;
  appDatatable: any;
}) => {
  let connectedTableList: { [key: string]: any } = {};
  const { finalTabeData, finalConectedTableId, belongsToTableId } = getInnerData({
    tableId,
    appDatatable,
  });

  if (finalTabeData?.tableID) {
    const finalTableID = getFinalTableID({
      tableID: finalTabeData?.tableID,
    });
    // Added main table to the connectedTableList object
    connectedTableList = {
      ...connectedTableList,
      [finalTableID]: {
        ...finalTabeData,
        relationType: 'MAIN',
        screenName: finalTabeData?.name,
        nameWithRelationType: `${finalTabeData?.name} ( MAIN )`,
      },
    };
    // adding belongs to relation of main table to the connectedTableList object
    (belongsToTableId ?? []).forEach(
      ({ tableId, relationType, relationID, relationName, screenName }: any) => {
        const { belongsToTableId } = getInnerData({
          tableId,
          appDatatable,
        });

        // if (!connectedTableList[tableId]) {
        const finalTableID = getFinalTableID({
          tableID: tableId,
          relationType: relationType,
          relationID: relationID,
        });
        // adding belongsToTables of belongs to table related to main table
        let innerBelongsToTable: { [key: string]: any } = {};
        (belongsToTableId ?? []).forEach(
          ({
            tableId,
            relationType,
            relationID: innerRelationID,
            screenName,
            relationName,
          }: any) => {
            // if (!connectedTableList[tableId]) {
            const finalTableID = getFinalTableID({
              tableID: tableId,
              relationType: relationType,
              relationID: innerRelationID,
            });
            innerBelongsToTable = {
              ...innerBelongsToTable,
              [finalTableID]: {
                ...(appDatatable[tableId] ?? {}),
                relationType,
                relationID: innerRelationID,
                parentRelationID: relationID,
                relationName,
                screenName,
                nameWithRelationType: `${appDatatable[tableId]?.name} ( ${relationType} )`,
              },
            };
            // }
          }
        );
        connectedTableList = {
          ...connectedTableList,
          [finalTableID]: {
            ...(appDatatable[tableId] ?? {}),
            relationType,
            relationID,
            relationName,
            innerBelongsToTable,
            screenName,
            nameWithRelationType: `${appDatatable[tableId]?.name} ( ${relationType} )`,
          },
        };
        // }
      }
    );

    // adding hasMany, hasOne, belongsToMany related tables to the connectedTableList object
    (finalConectedTableId ?? []).forEach(
      ({ tableId, relationType, relationID, screenName, relationName }: any) => {
        const { finalTabeData, belongsToTableId } = getInnerData({
          tableId,
          appDatatable,
        });
        if (finalTabeData?.tableID) {
          if (!connectedTableList[finalTabeData?.tableID]) {
            const finalTableID = getFinalTableID({
              tableID: finalTabeData?.tableID,
              relationType: relationType,
              relationID: relationID,
            });

            // adding belongsTo related tables of the related tables( hasMany, hasOne, belongsTOmany) that is related to main table; Thus making it to level 3
            let connectedTableBelongsToTables: { [key: string]: any } = {};

            (belongsToTableId ?? []).forEach(
              ({
                tableId,
                relationType,
                relationID: innerRelationID,
                screenName,
                relationName,
              }: any) => {
                // if (!connectedTableList[tableId]) {
                const finalTableID = getFinalTableID({
                  tableID: tableId,
                  relationType: relationType,
                  relationID: innerRelationID,
                });
                connectedTableBelongsToTables = {
                  ...connectedTableBelongsToTables,
                  [finalTableID]: {
                    ...(appDatatable[tableId] ?? {}),
                    relationType,
                    relationName,
                    relationID: innerRelationID,
                    parentRelationID: relationID,
                    screenName,
                    nameWithRelationType: `${appDatatable[tableId]?.name} ( ${relationType} )`,
                  },
                };
                // }
              }
            );

            connectedTableList = {
              ...connectedTableList,
              [finalTableID]: {
                ...finalTabeData,
                relationType,
                relationID,
                relationName,
                screenName,
                innerBelongsToTable: connectedTableBelongsToTables,
                nameWithRelationType: `${
                  appDatatable[finalTabeData?.tableID]?.name
                } ( ${relationType} )`,
              },
            };
          }
        }
      }
    );
  }
  return connectedTableList;
};
const specialDataType = ['hasOne', 'hasMany', 'belongsToMany'];
export const getFinalTableID = ({
  tableID,
  relationID,
  relationType,
}: {
  tableID: string;
  relationID?: string;
  relationType?: string;
}) => {
  if (!relationType || !relationID) {
    const finalTableID = `${tableID}`;
    return finalTableID;
  }
  const finalTableID = `${tableID}-${relationType}-${relationID}`;
  return finalTableID;
};
export const getInnerData = ({ tableId, appDatatable }: { tableId: string; appDatatable: any }) => {
  let finalTabeData: { [key: string]: any } = {};
  let finalConectedTableId: any[] = [];
  let belongsToTableId: any[] = [];
  const tableData = appDatatable[tableId];
  if (tableData) {
    finalTabeData = tableData;
    if (tableData?.relations) {
      Object.values(tableData?.relations ?? {}).forEach((tableElem: any) => {
        const isSpecialDataType = specialDataType.some((elem) => elem === tableElem?.type);
        if (isSpecialDataType) {
          const tableData = appDatatable?.[tableElem?.target];
          if (tableData) {
            finalConectedTableId = [
              ...finalConectedTableId,
              {
                tableId: tableElem?.target,
                relationName: tableElem?.relationName,
                relationType: tableElem?.type,
                screenName: `${tableElem?.target}`,
                relationID: tableElem?.as,
                // screenName: `Screen - ${tableElem?.target}`,
              },
            ];
          }
        }
        if ('belongsTo' === tableElem?.type) {
          const belongsToTableData = appDatatable?.[tableElem?.target];
          if (belongsToTableData) {
            belongsToTableId = [
              ...belongsToTableId,
              {
                tableId: tableElem?.target,
                relationType: tableElem?.type,
                relationID: tableElem?.as,
                screenName: `${tableElem?.target}`,
                relationName: tableElem?.relationName,
                // screenName: `Screen - ${tableElem?.target}`,
              },
            ];
          }
        }
      });
    }
  }

  return { finalTabeData, finalConectedTableId, belongsToTableId };
};

interface removedTableHandlingFunctionProps {
  componentTableList: { [key: string]: any }[];
  defaultTableList: { [key: string]: any }[];
}
export const removedTableHandlingFunction = ({
  componentTableList,
  defaultTableList,
}: removedTableHandlingFunctionProps) => {
  if (!componentTableList) {
    return defaultTableList;
  }
  let newTableList: { [key: string]: any }[] = [];
  (componentTableList ?? []).forEach((tableElem: any) => {
    const selectedDefaultTableList = (defaultTableList ?? []).find(
      (defaultTableListElem: { [key: string]: any }) => {
        return defaultTableListElem?.relationID === tableElem?.relationID;
      }
    );

    if (selectedDefaultTableList) {
      const newDefaultView = (tableElem?.defaultView ?? []).filter(
        (tableElemDefaultViewElem: { [key: string]: any }) => {
          const isPresent = (selectedDefaultTableList?.defaultView ?? []).some(
            (selectedDefaultTableListDefaultViewElem: { [key: string]: any }) => {
              return (
                selectedDefaultTableListDefaultViewElem?.columnName ===
                tableElemDefaultViewElem?.columnName
              );
            }
          );
          return isPresent;
        }
      );
      let newInnerTableList: { [key: string]: any }[] = [];
      (tableElem?.tableList ?? []).forEach((tableElem: { [key: string]: any }) => {
        const selectedDefaultInnerTableList = (selectedDefaultTableList?.tableList).find(
          (defaultTableListElem: { [key: string]: any }) => {
            return defaultTableListElem?.relationID === tableElem?.relationID;
          }
        );
        if (selectedDefaultInnerTableList) {
          const newDefaultView = (tableElem?.defaultView ?? []).filter(
            (tableElemDefaultViewElem: { [key: string]: any }) => {
              const isPresent = (selectedDefaultInnerTableList?.defaultView ?? []).some(
                (selectedDefaultTableListDefaultViewElem: { [key: string]: any }) => {
                  return (
                    selectedDefaultTableListDefaultViewElem?.columnName ===
                    tableElemDefaultViewElem?.columnName
                  );
                }
              );
              return isPresent;
            }
          );
          newInnerTableList = [...newInnerTableList, { ...tableElem, defaultView: newDefaultView }];
        }
      });
      newTableList = [
        ...newTableList,
        { ...tableElem, defaultView: newDefaultView, tableList: newInnerTableList },
      ];
    }
  });
  return newTableList;
};

export const getAppComponentFilterColumnSchema = ({
  connectedTablesData,
  tableData,
  finalTableID,
}: {
  connectedTablesData: any;
  tableData: any;
  finalTableID: string;
}) => {
  let allColumnSchema: any[] = [];
  let connectedTableElem: null | { [key: string]: any } = null;
  if (tableData?.isInnerTable) {
    const selectedOuterTable = connectedTablesData?.[tableData?.outerTableID];
    connectedTableElem = selectedOuterTable?.innerBelongsToTable?.[finalTableID];
  } else {
    connectedTableElem = connectedTablesData?.[finalTableID];
  }

  if (connectedTableElem) {
    const tableName = connectedTableElem?.tableID;
    let relationData: { [key: string]: any } = { relationType: connectedTableElem?.relationType };
    if (connectedTableElem?.relationType !== 'MAIN') {
      relationData = {
        ...relationData,
        relationID: connectedTableElem?.relationID,
      };
    }
    const newColumnSchema = (connectedTableElem?.columnSchema ?? [])?.map(
      (columnSchemaElem: any) => ({
        ...columnSchemaElem,
        tableName: tableName,
        ...relationData,
      })
    );
    allColumnSchema = [...allColumnSchema, ...newColumnSchema];
    if (connectedTableElem?.innerBelongsToTable) {
      Object.keys(connectedTableElem?.innerBelongsToTable ?? {}).forEach(
        (innerBelongsToTableElemKey: string) => {
          const innerBelongsToTableElem =
            connectedTableElem?.innerBelongsToTable?.[innerBelongsToTableElemKey];
          const tableName = innerBelongsToTableElem?.tableID;
          let relationData: { [key: string]: any } = {
            relationType: innerBelongsToTableElem?.relationType,
          };
          const newColumnSchema = (innerBelongsToTableElem?.columnSchema ?? [])?.map(
            (columnSchemaElem: any) => ({
              ...columnSchemaElem,
              tableName: tableName,
              ...relationData,
            })
          );
          allColumnSchema = [...allColumnSchema, ...newColumnSchema];
        }
      );
    }
  }
  return allColumnSchema;
};
