import reduxConstants from 'lib/reusable-components/reusableUtils/redux/reduxConstants';
import { create } from 'lib/reusable-components/reusableUtils/redux/stateSlice';
import { supistaAuthApiPost } from 'lib/server-connection/connections';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

const useLogout = () => {
  const dispatch = useDispatch();

  const logout = useCallback(
    async (appsConnected) => {
      try {
        // Perform API calls for logout
        await Promise.all(
          appsConnected.map((appID: string) => {
            const url = `auth/${appID}/logout`;
            return supistaAuthApiPost(url, { data: {} })
              .then((response: any) => {})
              .catch((error: any) => {
                console.error(`Logout failed for appID: ${appID}`, error);
                return null;
              });
          })
        );

        // Dispatch redux actions and clear localStorage after promises resolve
        dispatch(
          create({
            setPath: reduxConstants.config.LOGIN,
            value: { isLoggedin: false },
          })
        );
        dispatch(
          create({
            setPath: reduxConstants.config.USER_SCHEMA,
            value: {},
          })
        );
        dispatch(
          create({
            setPath: reduxConstants.config.APP_SCHEMA,
            value: {},
          })
        );
        dispatch(
          create({
            setPath: reduxConstants.config.PLUGINS_SCHEMA,
            value: {},
          })
        );
        dispatch(
          create({
            setPath: reduxConstants.config.USER_ACCESS_SCHEMA,
            value: {},
          })
        );
        localStorage.clear();
      } catch (error) {
        console.error('Error during logout:', error);
      }
    },
    [dispatch]
  );

  return { logout };
};

export default useLogout;
