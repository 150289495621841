import { getFinalTableID, getInnerData } from '../../utils/componentSettingFunction';

export const getConnectedTables = ({
  tableId,
  appDatatable,
}: {
  tableId: string;
  appDatatable: any;
}) => {
  let connectedTableList: { [key: string]: any } = {};

  const { finalTabeData, finalConectedTableId, belongsToTableId } = getInnerData({
    tableId,
    appDatatable,
  });
  if (finalTabeData?.tableID) {
    const finalTableID = getFinalTableID({
      tableID: finalTabeData?.tableID,
    });
    // level 1
    connectedTableList = {
      ...connectedTableList,
      [finalTableID]: {
        ...finalTabeData,
        relationType: 'MAIN',
        screenName: finalTabeData?.name,
        nameWithRelationType: `${finalTabeData?.name} ( MAIN )`,
      },
    };
    (belongsToTableId ?? []).forEach(
      ({ tableId, relationType, relationID, relationName, screenName }: any) => {
        if (!connectedTableList[tableId]) {
          const finalTableID = getFinalTableID({
            tableID: tableId,
            relationType: relationType,
            relationID: relationID,
          });
          connectedTableList = {
            ...connectedTableList,
            [finalTableID]: {
              ...(appDatatable[tableId] ?? {}),
              relationType,
              relationID,
              relationName,
              screenName,
              nameWithRelationType: `${appDatatable[tableId]?.name} ( ${relationType} )`,
            },
          };
        }
      }
    );
    (finalConectedTableId ?? []).forEach(
      ({ tableId, relationType, relationID, screenName, relationName }: any) => {
        const { finalTabeData, finalConectedTableId, belongsToTableId } = getInnerData({
          tableId,
          appDatatable,
        });
        if (finalTabeData?.tableID) {
          // level 2
          if (!connectedTableList[finalTabeData?.tableID]) {
            const finalTableID = getFinalTableID({
              tableID: finalTabeData?.tableID,
              relationType: relationType,
              relationID: relationID,
            });
            connectedTableList = {
              ...connectedTableList,
              [finalTableID]: {
                ...finalTabeData,
                relationType,
                relationID,
                relationName,
                screenName,
                nameWithRelationType: `${
                  appDatatable[finalTabeData?.tableID]?.name
                } ( ${relationType} )`,
              },
            };
          }
          (belongsToTableId ?? []).forEach(
            ({ tableId, relationType, relationID, screenName, relationName }: any) => {
              if (!connectedTableList[tableId]) {
                const finalTableID = getFinalTableID({
                  tableID: tableId,
                  relationType: relationType,
                  relationID: relationID,
                });
                connectedTableList = {
                  ...connectedTableList,
                  [finalTableID]: {
                    ...(appDatatable[tableId] ?? {}),
                    relationType,
                    relationID,
                    relationName,
                    screenName,
                    nameWithRelationType: `${appDatatable[tableId]?.name} ( ${relationType} )`,
                  },
                };
              }
            }
          );
        }
      }
    );
  }

  return connectedTableList;
};

export const tableDataToRelatedTableData = ({ tableData }: { tableData: any }) => {
  let defaultView: any[] = [];
  (tableData?.columnSchema ?? []).forEach((elem: any) => {
    const isStartWithD3 = (elem?.columnName ?? '').startsWith('__d3__');
    if (!isStartWithD3) {
      // for belongsTo Table add only belongsTo type columns
      if (tableData?.relationType === 'belongsTo') {
        if (elem?.dataType === 'belongsTo') {
          defaultView = [
            ...defaultView,
            {
              columnName: elem?.columnName ?? '',
              filters: {},
              name: elem?.name ?? '',
              description: '',
            },
          ];
        }
      } else {
        // otherWise add all type of columns
        defaultView = [
          ...defaultView,
          {
            columnName: elem?.columnName ?? '',
            filters: {},
            name: elem?.name ?? '',
            description: '',
          },
        ];
      }
    }
  });
  if (tableData?.relationType === 'belongsTo') {
    const id = {
      columnName: 'id',
      filters: {},
      name: 'id',
      description: '',
    };
    return {
      defaultView: [id, ...defaultView],
      screenName: tableData?.screenName,
      tableID: tableData?.tableID,
      relationType: tableData?.relationType,
      relationID: tableData?.relationID,
      description: '',
    };
  } else if (tableData?.relationType === 'MAIN') {
    return {
      defaultView: defaultView ?? [],
      tableID: tableData?.tableID,
      relationType: tableData?.relationType,
      // relationID: tableData?.relationID,
    };
  }
  return {
    defaultView: defaultView ?? [],
    relationType: tableData?.relationType,
    tableID: tableData?.tableID,
    screenName: tableData?.screenName,
    relationID: tableData?.relationID,
    description: '',
  };
};

export const getAppComponentColumnSchema = ({
  connectedTableList,
  connectedTablesData,
}: {
  connectedTableList: string[];
  connectedTablesData: any;
}) => {
  let allColumnSchema: any[] = [];
  (connectedTableList ?? []).forEach((connectedTablekey) => {
    const connectedTableElem = connectedTablesData?.[connectedTablekey] ?? {};
    let relationData: { [key: string]: any } = { relationType: connectedTableElem?.relationType };
    if (connectedTableElem?.relationType !== 'MAIN') {
      relationData = {
        ...relationData,
        relationID: connectedTableElem?.relationID,
      };
    }
    const newColumnSchema = (connectedTableElem?.columnSchema ?? [])?.map(
      (columnSchemaElem: any) => ({
        ...columnSchemaElem,
        tableName: connectedTablekey,
        ...relationData,
      })
    );
    allColumnSchema = [...allColumnSchema, ...newColumnSchema];
  });
  return allColumnSchema;
};
