import React from 'react';
import styles from './PDFTemplate.module.scss';
import SlideSwitch from 'lib/reusable-components/Components/SlideSwitch/SlideSwitch';
import { mls } from 'lib/multilanguagesupport';

interface connectedTableSelectionProps {
  connectedTablesData: { [key: string]: any };
  templateData: any;
  componentSchema: any;
  setTemplateData: Function;
}

const ConnectedTableSelection = ({
  connectedTablesData,
  templateData,
  setTemplateData,
  componentSchema,
}: connectedTableSelectionProps) => {
  if (!Object.keys(connectedTablesData ?? {})[1]) {
    return <></>;
  }
  return (
    <>
      <div className={styles.tablesSelectionTitle}>{mls('Choose Connected Tables')}</div>
      <div className={styles.tablesSelectionContainer}>
        {React.Children.toArray(
          Object.keys(connectedTablesData).map((connectedTableKey) => {
            // if (componentSchema?.table === connectedTableKey) {
            //   return <></>;
            // }

            const connectedTableFinalData =
              connectedTablesData[connectedTableKey as keyof typeof connectedTablesData];
            const isSelected = (templateData?.connectedTableList ?? []).some(
              (connectedTableElem: string) => connectedTableElem === connectedTableKey
            );
            const handleSelection = () => {
              if (componentSchema?.table === connectedTableKey) {
                return;
              }
              let finalData = [];
              if (isSelected) {
                finalData = (templateData?.connectedTableList ?? []).filter(
                  (element: string) => element !== connectedTableKey
                );
              } else {
                finalData = [...(templateData?.connectedTableList ?? []), connectedTableKey];
              }
              // setConnectedTableList(finalData);
              setTemplateData({ ...templateData, connectedTableList: finalData });
            };
            return (
              <div className={styles.columnSelectionDiv} onClick={() => handleSelection()}>
                <SlideSwitch
                  isChecked={isSelected}
                  setIsChecked={handleSelection}
                  disabled={componentSchema?.table === connectedTableKey}
                />
                {connectedTableFinalData?.name ?? connectedTableFinalData?.tableID}
              </div>
            );
          })
        )}
      </div>
    </>
  );
};

export default ConnectedTableSelection;
