import { mls } from 'lib/multilanguagesupport';
import PopUpDivChild from 'lib/reusable-components/PopUpDivChild/PopUpDivChild';
import React, { useEffect, useState } from 'react';
import styles from './aiCustomizationEditorContainer.module.scss';
import CheckBox from 'lib/reusable-components/Components/CheckBox/CheckBox';
import MoreActionContainerRight from './MoreActionContainerRight';
import SearchInputDiv from 'lib/reusable-components/Components/SearchInputDiv/SearchInputDiv';

interface moreActionsProps {
  isMoreActionPopup: boolean;
  setIsMoreActionPopup: Function;
  componentSchema: any;
  updateMoreActionFunction: Function;
  selectedData: any;
}
const actionButtonType = {
  codeType: {
    key: 'codeType',
    name: 'Code Type',
  },
  pdfType: {
    key: 'pdfType',
    name: 'PDF Type',
  },
  emailType: {
    key: 'emailType',
    name: 'Email Type',
  },
};

const MoreActions = ({
  isMoreActionPopup,
  setIsMoreActionPopup,
  componentSchema,
  updateMoreActionFunction,
  selectedData,
}: moreActionsProps) => {
  const moreAction = selectedData?.moreAction ?? [];
  const [downloadsData, setDownloadsData] = useState(componentSchema?.PDFTemplate ?? {});
  const [downloadsDataSearch, setDownloadsDataSearch] = useState('');
  useEffect(() => {
    const templateData = componentSchema?.PDFTemplate ?? {};
    let newDownloadData = {};
    Object.keys(templateData ?? {}).forEach((templateKey: string) => {
      const selectedTemplateData = templateData[templateKey];
      if (
        (selectedTemplateData?.title ?? '')
          .toLowerCase()
          ?.includes(downloadsDataSearch.toLowerCase())
      ) {
        newDownloadData = { ...newDownloadData, [templateKey]: selectedTemplateData };
      }
    });
    setDownloadsData(newDownloadData);
  }, [downloadsDataSearch, componentSchema?.PDFTemplate]);
  const [notificationData, setNotificationData] = useState(componentSchema?.emailTemplate ?? {});
  const [notificationDataSearch, setNotificationDataSearch] = useState('');
  useEffect(() => {
    const templateData = componentSchema?.emailTemplate ?? {};
    let newDownloadData = {};
    Object.keys(templateData ?? {}).forEach((templateKey: string) => {
      const selectedTemplateData = templateData[templateKey];
      if (
        (selectedTemplateData?.title ?? '')
          .toLowerCase()
          ?.includes(notificationDataSearch.toLowerCase())
      ) {
        newDownloadData = { ...newDownloadData, [templateKey]: selectedTemplateData };
      }
    });
    setNotificationData(newDownloadData);
  }, [notificationDataSearch, componentSchema?.emailTemplate]);

  const updateMoreActionData = (e: any) => {
    const isRemove = e?.isRemove;
    const target = e?.target;
    if (isRemove) {
      const newMoreAction = (moreAction ?? []).filter(
        (elem: any) => elem.templateKey !== target?.templateKey
      );
      updateMoreActionFunction(newMoreAction);
    } else {
      const newMoreAction = [
        ...moreAction,
        {
          templateKey: target?.templateKey,
          type: target.type,
        },
      ];
      updateMoreActionFunction(newMoreAction);
    }
  };

  return (
    <div>
      <PopUpDivChild
        initialValue={isMoreActionPopup}
        setPopUp={setIsMoreActionPopup}
        popupTitle={mls('More Actions')}
      >
        <div className={styles.moreActionContainer}>
          <div className={styles.moreActionContainerLeft}>
            <div className={styles.actionSelectionContainer}>
              <div className={styles.actionSelectionContainerTitle}>{mls('Downloads')}</div>
              <div className={styles.actionSelectionContainerSubTitle}>
                <SearchInputDiv
                  value={downloadsDataSearch}
                  onChange={setDownloadsDataSearch}
                  border
                />
              </div>
              <div className={styles.actionSelectionInnerContainer}>
                {Object.keys(downloadsData ?? {}).map((templateKey: string) => {
                  const selectedTemplateData = downloadsData[templateKey];
                  const templateData = {
                    templateKey: templateKey,
                    type: actionButtonType.pdfType.key,
                    title: selectedTemplateData?.title,
                  };
                  const isSelected = (moreAction ?? []).some((moreActionElem: any) => {
                    return moreActionElem.templateKey === templateData.templateKey;
                  });
                  return (
                    <div
                      className={styles.actionSelectionInnerElem}
                      onClick={() => {
                        updateMoreActionData({ isRemove: isSelected, target: templateData });
                      }}
                    >
                      <CheckBox checked={isSelected} onClick={(action: any) => {}} />{' '}
                      {templateData.title}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className={styles.moreActionContainerLeft}>
            <div className={styles.actionSelectionContainer}>
              <div className={styles.actionSelectionContainerTitle}>{mls('Notifications')}</div>
              <div className={styles.actionSelectionContainerSubTitle}>
                <SearchInputDiv
                  value={notificationDataSearch}
                  onChange={setNotificationDataSearch}
                  border
                />
              </div>
              <div className={styles.actionSelectionInnerContainer}>
                {Object.keys(notificationData).map((templateKey: string) => {
                  const selectedTemplateData = notificationData[templateKey];
                  const templateData = {
                    templateKey: templateKey,
                    type: actionButtonType.emailType.key,
                    title: selectedTemplateData?.title,
                  };
                  const isSelected = (moreAction ?? []).some((moreActionElem: any) => {
                    return moreActionElem.templateKey === templateData.templateKey;
                  });
                  return (
                    <div
                      className={styles.actionSelectionInnerElem}
                      onClick={() => {
                        updateMoreActionData({ isRemove: isSelected, target: templateData });
                      }}
                    >
                      <CheckBox checked={isSelected} onClick={(action: any) => {}} />{' '}
                      {templateData.title}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <MoreActionContainerRight
            moreAction={moreAction}
            setMoreAction={updateMoreActionFunction}
            componentSchema={componentSchema}
          />
        </div>
      </PopUpDivChild>
    </div>
  );
};

export default MoreActions;
