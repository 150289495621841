import React, { useEffect, useState } from 'react';
import SlideSwitch from './features/SlideSwitch/SlideSwitch';
import { getUserLevelAccessColumn } from './utils/filterHelperFunctions';
import { defaultDataTypeAllData } from './data/defaultFilterSchema';

interface userLevelAccessTableProps {
  selectedColumns: any[];
  setSelectedColumns: Function;
  mainTableID: string;
}

const UserLevelAccessTable = ({
  selectedColumns,
  setSelectedColumns,
  mainTableID,
}: userLevelAccessTableProps) => {
  const target = getUserLevelAccessColumn({ tableID: mainTableID });
  const [isChecked, setisChecked] = useState(false);
  const stopPropagation = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
  };
  const handleColumnSelection = (selectedItems: any) => {
    const newSelectedData = [...selectedColumns, selectedItems];
    setSelectedColumns(newSelectedData);
  };
  const handleRemoveColumnSelection = (selectedItems: any) => {
    const newArray = selectedColumns.filter((obj) => {
      return `${obj.columnName}` !== `${target.columnName}`;
    });
    setSelectedColumns(newArray);
  };
  const addFilter = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();

    setisChecked((currentState) => {
      if (!currentState) {
        handleColumnSelection(target);
        return !currentState;
      }
      return currentState;
    });
  };
  const handleChecked = () => {
    setisChecked((currentState) => {
      if (!currentState) {
        handleColumnSelection(target);
      } else {
        handleRemoveColumnSelection(target);
      }
      return !currentState;
    });
  };

  useEffect(() => {
    const column = `${target.tableName}.${target.columnName}`;
    const isTrue = selectedColumns.some((e: any) => `${e.tableName}.${e.columnName}` === column);
    setisChecked(isTrue);
  }, [selectedColumns]);
  const dataTypeElem = defaultDataTypeAllData?.[target?.dataType];
  const Icon = dataTypeElem?.icon ?? null;
  const isFunction = typeof Icon === 'function';
  return (
    <div
      className='filterTable'
      style={{ border: '1px solid #DFE2EF' }}
      title={target?.name}
      onClick={(e) => addFilter(e)}
    >
      <div className='titleSection'>
        <div className='titleSectionIcon' style={{ fontSize: dataTypeElem?.fontSize }}>
          {isFunction ? <Icon /> : dataTypeElem?.icon}
        </div>
        <div className='titleSectionText'>{target?.name}</div>
      </div>
      <div className='toggleSection' onClick={(e) => stopPropagation(e)}>
        <SlideSwitch setIsChecked={handleChecked} isChecked={isChecked} />
      </div>
    </div>
  );
};

export default UserLevelAccessTable;
const userlevelAccess = {
  userlevelAccess: {
    columnName: 'userlevelAccess',
    dataType: 'userlevelAccess',
    tableName: 'currentTableId',
    value: [
      {
        relationId: 'sdfdf-dsfdf-sdfsdf-sdf',
        columnName: '__d3__id__sdfdf-dsfdf-sdfsdf-sdf',
      },
      {
        relationId: 'sdfdf-dsfdf-sdfsdf-sdf2',
        columnName: '__d3__id__sdfdf-dsfdf-sdfsdf-sdf2',
      },
      {
        isUserID: true,
        relationId: 'sdfdf-dsfdf-sdfsdf-sdf3',
        columnName: '__d3__id__sdfdf-dsfdf-sdfsdf-sdf3',
        userIdColumnKey: 'userIdColumnKeyValue', // user datatype
      },
    ],
  },
};
